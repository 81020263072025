import {
  UpdateCustomerCodeAction,
  UPDATE_CUSTOMER_CODE,
} from "./customer-code.action-type";

export const updateCustomerCode: (
  customerCode: string
) => UpdateCustomerCodeAction = (customerCode: string) => {
  return {
    type: UPDATE_CUSTOMER_CODE,
    payload: customerCode,
  } as UpdateCustomerCodeAction;
};
