import { DriverOrder } from "../../../graphql/types";
import {
  OrderDetailsActionTypes,
  UPDATE_GEOFENCE_EVENTS,
  UPDATE_ORDER_DETAILS,
} from "./order-details.action-type";

export interface OrderDetailsState {
  orderDetails: DriverOrder | null;
}
const initialState = {
  orderDetails: null,
} as OrderDetailsState;

export const orderDetailsReducer = (
  state = initialState,
  action: OrderDetailsActionTypes
): OrderDetailsState => {
  switch (action.type) {
    case UPDATE_ORDER_DETAILS: {
      return {
        ...state,
        orderDetails: Object.assign({}, action.payload),
      };
    }
    case UPDATE_GEOFENCE_EVENTS: {
      const order = Object.assign({}, state.orderDetails);
      order.geofencingEvents = action.payload.geofencingEvents;
      return {
        ...state,
        orderDetails: order,
      };
    }
    default:
      return state;
  }
};
