import { connect, ConnectedProps } from "react-redux";
import { CustomerCodeComponent } from "./customer-code.component";
import { updateCustomerCode } from "../../redux/reducers/customer-code/customer-code.actions";
import { addMessage } from "../../redux/reducers/messages/messages.actions";
import { RootState } from "../../redux/reducers/root.reducer";
import { fetchOrderDetails } from "../../redux/reducers/order-details/order-details.actions";
import {
  subscribeToOrder,
  subscribeToGeoFenceUpdates,
} from "../../redux/reducers/order-details/order-details.actions";
import {
  subscribeToOrderLocation,
  fetchOrderLocation,
} from "../../redux/reducers/order-location/order-location.actions";

const mapState = (state: RootState) => ({
  orderDetails: state.orderDetailsReducer.orderDetails,
});
const mapDispatch = {
  updateCustomerCode,
  addMessage,
  subscribeToOrder,
  subscribeToOrderLocation,
  subscribeToGeoFenceUpdates,
  fetchOrderDetails,
  fetchOrderLocation,
};

const connector = connect(mapState, mapDispatch);

export type CustomerCodePropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CustomerCodeComponent);
