import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";
import { updateMarkerPoint } from "../../services/marker/marker.service";
import { getConfig } from "../../services/config/config.service";
import { removeTraversedRoute } from "../route/route.service";

let lastAnimation = 0;
let lastPointRecieved = 0;
let animationOff = false;
let currentRunningAnimation: number | null = null;

export const resetLastAnimation: () => void = () => {
  lastAnimation = 0;
};

export const animateAndRotateCar: (
  oldPoint: [number, number],
  newPoint: [number, number],
  car: mapboxgl.Marker,
  map: mapboxgl.Map,
  activeRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined,
  previousLocation?: [number, number]
) => void = (
  oldPoint: [number, number],
  newPoint: [number, number],
  car: mapboxgl.Marker,
  map: mapboxgl.Map,
  activeRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined,
  previousLocation?: [number, number]
) => {
  rotateCar(oldPoint, newPoint, car);
  if (lastAnimation === 0) {
    lastAnimation = performance.now();
  }
  const timeSinceLastRun = performance.now() - lastAnimation;
  if (
    timeSinceLastRun >
    getConfig().animationOffAnimationTimeThresholdSeconds * 1000
  ) {
    animationOff = true;
    requestAnimationFrame(() => {
      animationOff = false;
    });
  }
  const animationCoordinates = getAnimationCoordinates(oldPoint, newPoint);
  let animationCounter = 0;
  function animateCar() {
    if (animationCounter < animationCoordinates.length && !animationOff) {
      updateMarkerPoint(animationCoordinates[animationCounter], car);
      removeTraversedRoute(
        activeRoute,
        animationCoordinates[animationCounter],
        map,
        car.getRotation(),
        previousLocation
      );
      currentRunningAnimation = requestAnimationFrame(() => {
        lastAnimation = performance.now();
        animateCar();
      });
      animationCounter++;
    } else if (animationCounter >= animationCoordinates.length) {
      currentRunningAnimation = null;
    }
  }

  if (
    animationOff ||
    pointTimeAndDistanceThresholdCombinationsExceeded(oldPoint, newPoint)
  ) {
    updateMarkerPoint(newPoint, car);
    removeTraversedRoute(
      activeRoute,
      newPoint,
      map,
      car.getRotation(),
      previousLocation
    );
    lastAnimation = performance.now();
  } else {
    cancelRunningAnimation();
    animateCar();
  }
};

const pointTimeAndDistanceThresholdCombinationsExceeded = (
  oldPoint: [number, number],
  newPoint: [number, number]
) => {
  if (pointToPointDistanceThresholdExceeded(oldPoint, newPoint)) {
    return true;
  }
  return pointRecievedTimeThresholdExceeded();
};

export const pointToPointDistanceThresholdExceeded: (
  oldPoint: [number, number],
  newPoint: [number, number]
) => boolean = (oldPoint: [number, number], newPoint: [number, number]) => {
  const distance = turf.distance(oldPoint, newPoint, { units: "meters" });
  const threshold = getConfig().animationOffDistanceThresholdMeters;
  const result = distance > threshold;
  return result;
};

export const pointRecievedTimeThresholdExceeded: () => boolean = () => {
  if (lastPointRecieved === 0) {
    lastPointRecieved = performance.now();
  }
  const timeNow = performance.now();
  const timeSinceLastRun = timeNow - lastPointRecieved;
  const exceeded =
    timeSinceLastRun > getConfig().animationOffPointTimeThresholdSeconds * 1000;
  lastPointRecieved = timeNow;
  return exceeded;
};

const getAnimationCoordinates = (
  oldPoint: [number, number],
  newPoint: [number, number]
) => {
  const speedFactor = getConfig().animationSpeed;
  const diffX = newPoint[0] - oldPoint[0];
  const diffY = newPoint[1] - oldPoint[1];

  const sfX = diffX / speedFactor;
  const sfY = diffY / speedFactor;

  let i = 0;
  let j = 0;

  const animationCoordinates: [number, number][] = [];

  for (let k = 0; k < speedFactor; k++) {
    animationCoordinates.push([oldPoint[0] + i, oldPoint[1] + j]);

    if (Math.abs(i) < Math.abs(diffX)) {
      i += sfX;
    }

    if (Math.abs(j) < Math.abs(diffY)) {
      j += sfY;
    }
  }
  return animationCoordinates;
};

const cancelRunningAnimation = () => {
  if (currentRunningAnimation) {
    cancelAnimationFrame(currentRunningAnimation);
    currentRunningAnimation = null;
  }
};

export const setCarAnnimationOff: (off?: boolean) => void = (off?: boolean) => {
  animationOff = off ?? true;
};

export const rotateCar: (
  oldPoint: turf.helpers.Position,
  newPoint: turf.helpers.Position,
  car: mapboxgl.Marker
) => void = (
  oldPoint: turf.helpers.Position,
  newPoint: turf.helpers.Position,
  car: mapboxgl.Marker
) => {
  const newRotation = turf.rhumbBearing(
    turf.point(oldPoint),
    turf.point(newPoint)
  );
  if (
    animationOff ||
    pointTimeAndDistanceThresholdCombinationsExceeded(
      oldPoint as [number, number],
      newPoint as [number, number]
    )
  ) {
    car.setRotation(newRotation);
  } else {
    const currentRotation = car.getRotation();

    const steps = buildStepsForRotation(currentRotation, newRotation);

    let step = 0;
    const updateAngle = () => {
      if (step < steps.length && !animationOff) {
        car.setRotation(steps[step]);
        requestAnimationFrame(updateAngle);
        step++;
      }
    };
    updateAngle();
  }
};

const convertRotationToBearing = (bearing: number) => {
  if (bearing >= 0) {
    return bearing;
  } else {
    return 360 + bearing;
  }
};

const convertBearingToRotation: (angle: number) => number = (angle: number) => {
  if (angle <= 180 && angle > -180) {
    return angle;
  } else if (angle < -180) {
    return angle + 360;
  } else {
    return angle - 360;
  }
};

const buildStepsForRotation = (
  currentRotation: number,
  newRotation: number
) => {
  const newBearing = convertRotationToBearing(newRotation);
  const currentBearing = convertRotationToBearing(currentRotation);
  const change = getChangeOfBearing(currentBearing, newBearing);
  if (!change || Math.abs(change) < 1) {
    return [];
  }
  const rotationSpeed = getConfig().rotationSpeed;
  const stepSize = change / rotationSpeed;
  const result = [];
  for (let i = 0; i < rotationSpeed; i++) {
    result.push(convertBearingToRotation(stepSize * (i + 1) + currentRotation));
  }
  return result;
};

export const getChangeOfBearing: (
  currentBearing: number,
  newBearing: number
) => number = (currentBearing: number, newBearing: number) => {
  const alpha = newBearing - currentBearing;
  const beta = newBearing - currentBearing + 360;
  const ypsilon = newBearing - currentBearing - 360;

  const result = getSmallestAbsolute(alpha, beta, ypsilon);

  if (result === -180) {
    return 180;
  }
  return result;
};

const getSmallestAbsolute: (
  alpha: number,
  beta: number,
  ypsilon: number
) => number = (alpha: number, beta: number, ypsilon: number) => {
  const absAlpha = Math.abs(alpha);
  const absBeta = Math.abs(beta);
  const absYpsilon = Math.abs(ypsilon);
  if (absAlpha < absBeta && absAlpha < absYpsilon) {
    return alpha;
  } else if (absBeta < absYpsilon) {
    return beta;
  }
  return ypsilon;
};
