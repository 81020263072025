import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers/root.reducer";
import {
  addMessage,
  removeMessage,
} from "../../redux/reducers/messages/messages.actions";

import { DriverConnectionWarningComponent } from "./driver-connection-warning.component";

const mapState = (state: RootState) => ({
  timeStamp: state.orderLocationReducer.snappedOrderLocation?.timestamp,
  orderStatus: state.orderDetailsReducer.orderDetails?.status,
});

const mapDispatch = {
  addMessage,
  removeMessage,
};

const connector = connect(mapState, mapDispatch);

export type DriverConnectionWarningPropsFromRedux = ConnectedProps<
  typeof connector
>;

export default connector(DriverConnectionWarningComponent);
