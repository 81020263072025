import React, { useEffect, useState } from "react";
import { Message } from "../../models/message.model";
import { getConfig } from "../../services/config/config.service";
import { isOrderBeingDelivered } from "../../services/utils.service";
import { DriverConnectionWarningPropsFromRedux } from "./driver-connection-warning.container";
import {
  addMessageWatcher,
  clearWarningWatcher,
  removeMessageWatcher,
} from "./driver-connection-warning.watchers";

export const DriverConnectionWarningComponent: React.FC<DriverConnectionWarningPropsFromRedux> = (
  props: DriverConnectionWarningPropsFromRedux
) => {
  const [warningShown, setWarningShown] = useState<boolean>(false);
  const delayThreshold = getConfig().connectionWarningThresholdSeconds * 1000;
  const message = {
    message:
      "Could not update the driver location. Trying to reconnect to the driver’s device.",
    severity: "warning",
  } as Message;

  useEffect(() => {
    if (
      props.timeStamp &&
      delayThreshold &&
      !warningShown &&
      isOrderBeingDelivered(props.orderStatus)
    ) {
      if (isDelayThresholdPassed()) {
        showConnectionWarning();
      } else {
        const timer = setTimeout(() => showConnectionWarning(), delayThreshold);
        return () => {
          clearTimeout(timer);
        };
      }
    } else if (props.timeStamp && warningShown) {
      clearWarning();
    }
  }, [props.timeStamp, props.orderStatus]);

  const showConnectionWarning: () => void = () => {
    setWarningShown(true);
    props.addMessage(message);
    addMessageWatcher();
  };

  const clearWarning: () => void = () => {
    clearWarningWatcher();
    if (!isDelayThresholdPassed()) {
      setWarningShown(false);
      props.removeMessage(message);
      removeMessageWatcher();
    }
  };

  const isDelayThresholdPassed = () =>
    Date.parse(props.timeStamp) < Date.now() - delayThreshold;

  // for void component
  return null;
};
