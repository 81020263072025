import { Card, CardContent, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { OrderStatus } from "../../graphql/types";
import clockIcon from "../../images/clock_icon.svg";
import { EtaPropsFromRedux } from "./eta.container";

const useStyles = makeStyles(() => ({
  timerIcon: {
    color: "red",
    width: "20px",
    height: "20px",
    display: "inline-block",
    margin: "auto 0",
  },
  card: {
    backgroundColor: "#EA130C",
    width: "250px",
    height: "100%",
    marginLeft: "20px",
  },
  cardContent: {
    paddingBottom: "16px!important",
  },
  whiteText: {
    fontSize: "20px",
    lineHeight: "1em",
    margin: "0 10px 0 10px",
    display: "inline-block",
    color: "white",
  },
  center: {
    margin: "0 auto",
  },
  smallText: {
    fontSize: "10px",
    lineHeight: "9px",
    display: "block",
    color: "white",
  },
}));

export const EtaComponent: React.FC<EtaPropsFromRedux> = (
  props: EtaPropsFromRedux
) => {
  const classes = useStyles();
  const [clickCount, setClickCount] = useState<number>(0);

  const getEtaMinutes = () => {
    if (props.eta) {
      const difference = getMillisBetweenNowAndETA();
      const minutes = getMinutesFromMillis(difference);
      if (difference < 0 || minutes === 0) {
        return "Arriving";
      }
      return `${minutes} Minute${minutes > 1 ? "s" : ""}`;
    } else {
      return "Calculating ETA";
    }
  };

  const getMinutesFromMillis = (millis: number) => {
    const differenceDate = new Date(millis);
    let minutes = differenceDate.getMinutes();
    const hours = differenceDate.getUTCHours();
    if (hours > 0) {
      minutes += hours * 60;
    }
    return minutes;
  };

  const getMillisBetweenNowAndETA = () => {
    return new Date(props.eta).getTime() - new Date(Date.now()).getTime();
  };

  const getETATime = () => {
    if (clickCount > 5 && props.eta) {
      return new Date(props.eta).toLocaleTimeString();
    }
  };
  const click = () => {
    setClickCount(clickCount + 1);
  };
  if (props.orderStatus === OrderStatus.Started) {
    return (
      <div>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <span className={classes.center}>
              <img src={clockIcon} className={classes.timerIcon} />
              <span className={classes.whiteText} onClick={click}>
                {getEtaMinutes()}
              </span>
              <span className={classes.smallText}>
                {`Last Update: ${new Date(Date.now()).toLocaleTimeString()}`}
                {getETATime()}
              </span>
            </span>
          </CardContent>
        </Card>
      </div>
    );
  } else {
    return <div></div>;
  }
};
