export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
  BigInt: any;
  Double: any;
};

















export enum DeviceOperatingSystem {
  Ios = 'IOS',
  Android = 'ANDROID'
}

export type Directions = {
  hashcode: Scalars['String'];
  legs: Array<Legs>;
};

export type DirectionsInput = {
  hashcode: Scalars['String'];
  legs: Array<LegsInput>;
};


export type DriverDevice = {
  id: Scalars['String'];
  vendorRegistrations?: Maybe<Array<VendorRegistration>>;
  routes?: Maybe<Array<DriverRoute>>;
  dateCreated: Scalars['AWSDateTime'];
  operatingSystem: DeviceOperatingSystem;
  version: Scalars['Int'];
};

export type DriverDeviceInput = {
  id: Scalars['String'];
  vendorRegistrations?: Maybe<Array<VendorRegistrationInput>>;
  dateCreated: Scalars['AWSDateTime'];
  operatingSystem: DeviceOperatingSystem;
  version: Scalars['Int'];
};

export type DriverOrder = {
  status: OrderStatus;
  cancellationReason?: Maybe<Scalars['String']>;
  customerAccessCode: Scalars['String'];
  routePosition: Scalars['Int'];
  privacyZones?: Maybe<Array<PrivacyZone>>;
  directions?: Maybe<Directions>;
  addressOrganisation?: Maybe<Scalars['String']>;
  addressFirstLine?: Maybe<Scalars['String']>;
  addressSecondLine?: Maybe<Scalars['String']>;
  addressTown?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressPostcode: Scalars['String'];
  customerLocation: Location;
  scanType: OrderScanType;
  vendorId: Scalars['Int'];
  vendorLocation: Location;
  createdDate: Scalars['AWSDateTime'];
  startedDate?: Maybe<Scalars['AWSDateTime']>;
  finishedDate?: Maybe<Scalars['AWSDateTime']>;
  geofencingEvents?: Maybe<Array<GeofencingEvent>>;
  version: Scalars['Int'];
};

export type DriverOrderGeofenceSubset = {
  customerAccessCode: Scalars['String'];
  geofencingEvents?: Maybe<Array<GeofencingEvent>>;
};

export type DriverOrderGeofencingInput = {
  id: Scalars['String'];
  geofencingEvents?: Maybe<Array<GeofencingEventInput>>;
  version: Scalars['Int'];
};

export type DriverOrderInput = {
  id: Scalars['String'];
  scoffableDbId?: Maybe<Scalars['String']>;
  routeId: Scalars['String'];
  deviceId: Scalars['String'];
  status: OrderStatus;
  cancellationReason?: Maybe<Scalars['String']>;
  customerAccessCode: Scalars['String'];
  routePosition: Scalars['Int'];
  privacyZones?: Maybe<Array<PrivacyZoneInput>>;
  directions?: Maybe<DirectionsInput>;
  addressOrganisation?: Maybe<Scalars['String']>;
  addressFirstLine?: Maybe<Scalars['String']>;
  addressSecondLine?: Maybe<Scalars['String']>;
  addressTown?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressPostcode: Scalars['String'];
  customerLocation: LocationInput;
  departureNotificationSent: Scalars['Boolean'];
  etaOnDeparture?: Maybe<Scalars['AWSDateTime']>;
  scanType: OrderScanType;
  orderQrCode?: Maybe<Scalars['String']>;
  maskingServiceTelephoneNumber?: Maybe<Scalars['String']>;
  maskingServiceAuthCode?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  vendorId: Scalars['Int'];
  vendorLocation: LocationInput;
  createdDate: Scalars['AWSDateTime'];
  startedDate?: Maybe<Scalars['AWSDateTime']>;
  departureNotificationSentDate?: Maybe<Scalars['AWSDateTime']>;
  finishedDate?: Maybe<Scalars['AWSDateTime']>;
  geofencingEvents?: Maybe<Array<GeofencingEventInput>>;
  version: Scalars['Int'];
};

export type DriverRoute = {
  id: Scalars['String'];
  deviceId: Scalars['String'];
  orders?: Maybe<Array<DriverOrder>>;
  status: DriverRouteStatus;
  routingType: DriverRoutingType;
  startLocation: Location;
  estimatedRoundTripMinutes: Scalars['Int'];
  estimatedDeliveryDurationMinutes?: Maybe<Scalars['Int']>;
  estimatedSecondsPerStop: Scalars['Int'];
  dateCreated: Scalars['AWSDateTime'];
  dateFinalized?: Maybe<Scalars['AWSDateTime']>;
  dateCompleted?: Maybe<Scalars['AWSDateTime']>;
  durationMatrix?: Maybe<DurationMatrix>;
  version: Scalars['Int'];
};

export type DriverRouteInput = {
  id: Scalars['String'];
  deviceId: Scalars['String'];
  orders?: Maybe<Array<DriverOrderInput>>;
  status: DriverRouteStatus;
  routingType: DriverRoutingType;
  startLocation: LocationInput;
  estimatedRoundTripMinutes: Scalars['Int'];
  estimatedDeliveryDurationMinutes?: Maybe<Scalars['Int']>;
  estimatedSecondsPerStop: Scalars['Int'];
  dateCreated: Scalars['AWSDateTime'];
  dateFinalized?: Maybe<Scalars['AWSDateTime']>;
  dateCompleted?: Maybe<Scalars['AWSDateTime']>;
  durationMatrix?: Maybe<DurationMatrixInput>;
  version: Scalars['Int'];
};

export enum DriverRouteStatus {
  Editing = 'EDITING',
  Navigating = 'NAVIGATING',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED'
}

export enum DriverRoutingType {
  Manual = 'MANUAL',
  TimeOptimised = 'TIME_OPTIMISED'
}

export type DurationMatrix = {
  destinations: Array<Location>;
  sources: Array<Location>;
  durations?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
};

export type DurationMatrixInput = {
  destinations: Array<LocationInput>;
  sources: Array<LocationInput>;
  durations?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
};

export type GeofencingEvent = {
  eventType: GeofencingEventType;
};

export type GeofencingEventInput = {
  eventType: GeofencingEventType;
  triggeredDate: Scalars['AWSDateTime'];
};

export enum GeofencingEventType {
  OrderLeftVendor = 'ORDER_LEFT_VENDOR',
  OrderNearby = 'ORDER_NEARBY'
}

export type LatLong = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Legs = {
  steps: Array<Steps>;
  activeLeg: Scalars['Boolean'];
};

export type LegsInput = {
  steps: Array<StepsInput>;
  activeLeg: Scalars['Boolean'];
};

export type Location = LatLong & {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type LocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Mutation = {
  createDriverDevice?: Maybe<DriverDevice>;
  deleteDriverDevice?: Maybe<DriverDevice>;
  createDriverRoute?: Maybe<DriverRoute>;
  deleteDriverRoute?: Maybe<DriverRoute>;
  createDriverOrder?: Maybe<DriverOrder>;
  deleteDriverOrder?: Maybe<DriverOrder>;
  notifyDriverOrderUpdated?: Maybe<DriverOrder>;
  updateOrderGeofencingEvents?: Maybe<DriverOrderGeofenceSubset>;
  recordOrderLocation?: Maybe<OrderLocation>;
  recordOrderLocationInsidePrivacyZone?: Maybe<OrderLocation>;
};


export type MutationCreateDriverDeviceArgs = {
  driverDeviceInput?: Maybe<DriverDeviceInput>;
};


export type MutationDeleteDriverDeviceArgs = {
  deviceId?: Maybe<Scalars['String']>;
};


export type MutationCreateDriverRouteArgs = {
  driverRouteInput?: Maybe<DriverRouteInput>;
};


export type MutationDeleteDriverRouteArgs = {
  deviceId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
};


export type MutationCreateDriverOrderArgs = {
  driverOrderInput?: Maybe<DriverOrderInput>;
};


export type MutationDeleteDriverOrderArgs = {
  orderId?: Maybe<Scalars['String']>;
};


export type MutationNotifyDriverOrderUpdatedArgs = {
  orderId?: Maybe<Scalars['String']>;
};


export type MutationUpdateOrderGeofencingEventsArgs = {
  geofencingInput?: Maybe<DriverOrderGeofencingInput>;
};


export type MutationRecordOrderLocationArgs = {
  orderLocationInput?: Maybe<OrderLocationInput>;
};


export type MutationRecordOrderLocationInsidePrivacyZoneArgs = {
  orderLocationInput?: Maybe<OrderLocationInput>;
};

export type OrderLocation = {
  customerAccessCode: Scalars['String'];
  currentLocation: OrderLocationPoint;
  previousLocation?: Maybe<OrderLocationPoint>;
  eta?: Maybe<Scalars['AWSDateTime']>;
};

export type OrderLocationInput = {
  orderId: Scalars['String'];
  customerAccessCode: Scalars['String'];
  currentLocation: OrderLocationPointInput;
  eta?: Maybe<Scalars['AWSDateTime']>;
};

export type OrderLocationPoint = LatLong & {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  timestamp: Scalars['AWSDateTime'];
  inPrivacyZoneId?: Maybe<Scalars['String']>;
};

export type OrderLocationPointInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  timestamp: Scalars['AWSDateTime'];
  inPrivacyZoneId?: Maybe<Scalars['String']>;
};

export enum OrderScanType {
  ScoffableQrCode = 'SCOFFABLE_QR_CODE',
  GenericAddressTel = 'GENERIC_ADDRESS_TEL',
  JeOrder = 'JE_ORDER'
}

export enum OrderStatus {
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED'
}

export type PrivacyZone = {
  id: Scalars['String'];
  center: Location;
  radiusMetres: Scalars['Float'];
  transparent: Scalars['Boolean'];
  completed: Scalars['Boolean'];
};

export type PrivacyZoneInput = {
  id: Scalars['String'];
  orderId: Scalars['String'];
  center: LocationInput;
  radiusMetres: Scalars['Float'];
  transparent: Scalars['Boolean'];
  completed: Scalars['Boolean'];
};

export type Query = {
  getDriverDevice?: Maybe<DriverDevice>;
  getDriverRoute?: Maybe<DriverRoute>;
  getDriverOrder?: Maybe<DriverOrder>;
  getCustomerOrder?: Maybe<DriverOrder>;
  getOrderLocation?: Maybe<OrderLocation>;
};


export type QueryGetDriverDeviceArgs = {
  deviceId?: Maybe<Scalars['String']>;
};


export type QueryGetDriverRouteArgs = {
  deviceId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
};


export type QueryGetDriverOrderArgs = {
  orderId?: Maybe<Scalars['String']>;
};


export type QueryGetCustomerOrderArgs = {
  customerAccessCode?: Maybe<Scalars['String']>;
};


export type QueryGetOrderLocationArgs = {
  customerAccessCode?: Maybe<Scalars['String']>;
};

export type Steps = {
  encodedPolyline: Scalars['String'];
};

export type StepsInput = {
  encodedPolyline: Scalars['String'];
};

export type Subscription = {
  subscribeToOrderLocation?: Maybe<OrderLocation>;
  subscribeDriverOrder?: Maybe<DriverOrder>;
  subscribeToGeofencingUpdates?: Maybe<DriverOrderGeofenceSubset>;
};


export type SubscriptionSubscribeToOrderLocationArgs = {
  customerAccessCode: Scalars['String'];
};


export type SubscriptionSubscribeDriverOrderArgs = {
  customerAccessCode?: Maybe<Scalars['String']>;
};


export type SubscriptionSubscribeToGeofencingUpdatesArgs = {
  customerAccessCode?: Maybe<Scalars['String']>;
};

export type VendorRegistration = {
  id: Scalars['String'];
  vendorId: Scalars['Int'];
  vendorName: Scalars['String'];
  qrCodePayload: Scalars['String'];
  registrationDate: Scalars['AWSDateTime'];
};

export type VendorRegistrationInput = {
  id: Scalars['String'];
  vendorId: Scalars['Int'];
  vendorName: Scalars['String'];
  qrCodePayload: Scalars['String'];
  registrationDate: Scalars['AWSDateTime'];
};

export type CreateDriverDeviceMutationVariables = Exact<{
  driverDeviceInput?: Maybe<DriverDeviceInput>;
}>;


export type CreateDriverDeviceMutation = { createDriverDevice?: Maybe<(
    Pick<DriverDevice, 'id' | 'dateCreated' | 'operatingSystem' | 'version'>
    & { vendorRegistrations?: Maybe<Array<Pick<VendorRegistration, 'id' | 'vendorId' | 'vendorName' | 'qrCodePayload' | 'registrationDate'>>>, routes?: Maybe<Array<(
      Pick<DriverRoute, 'id' | 'deviceId' | 'status' | 'routingType' | 'estimatedRoundTripMinutes' | 'estimatedDeliveryDurationMinutes' | 'estimatedSecondsPerStop' | 'dateCreated' | 'dateFinalized' | 'dateCompleted' | 'version'>
      & { orders?: Maybe<Array<(
        Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
        & { privacyZones?: Maybe<Array<(
          Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
          & { center: Pick<Location, 'lat' | 'lng'> }
        )>>, directions?: Maybe<(
          Pick<Directions, 'hashcode'>
          & { legs: Array<Pick<Legs, 'activeLeg'>> }
        )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
      )>>, startLocation: Pick<Location, 'lat' | 'lng'>, durationMatrix?: Maybe<(
        Pick<DurationMatrix, 'durations'>
        & { destinations: Array<Pick<Location, 'lat' | 'lng'>>, sources: Array<Pick<Location, 'lat' | 'lng'>> }
      )> }
    )>> }
  )> };

export type DeleteDriverDeviceMutationVariables = Exact<{
  deviceId?: Maybe<Scalars['String']>;
}>;


export type DeleteDriverDeviceMutation = { deleteDriverDevice?: Maybe<(
    Pick<DriverDevice, 'id' | 'dateCreated' | 'operatingSystem' | 'version'>
    & { vendorRegistrations?: Maybe<Array<Pick<VendorRegistration, 'id' | 'vendorId' | 'vendorName' | 'qrCodePayload' | 'registrationDate'>>>, routes?: Maybe<Array<(
      Pick<DriverRoute, 'id' | 'deviceId' | 'status' | 'routingType' | 'estimatedRoundTripMinutes' | 'estimatedDeliveryDurationMinutes' | 'estimatedSecondsPerStop' | 'dateCreated' | 'dateFinalized' | 'dateCompleted' | 'version'>
      & { orders?: Maybe<Array<(
        Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
        & { privacyZones?: Maybe<Array<(
          Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
          & { center: Pick<Location, 'lat' | 'lng'> }
        )>>, directions?: Maybe<(
          Pick<Directions, 'hashcode'>
          & { legs: Array<Pick<Legs, 'activeLeg'>> }
        )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
      )>>, startLocation: Pick<Location, 'lat' | 'lng'>, durationMatrix?: Maybe<(
        Pick<DurationMatrix, 'durations'>
        & { destinations: Array<Pick<Location, 'lat' | 'lng'>>, sources: Array<Pick<Location, 'lat' | 'lng'>> }
      )> }
    )>> }
  )> };

export type CreateDriverRouteMutationVariables = Exact<{
  driverRouteInput?: Maybe<DriverRouteInput>;
}>;


export type CreateDriverRouteMutation = { createDriverRoute?: Maybe<(
    Pick<DriverRoute, 'id' | 'deviceId' | 'status' | 'routingType' | 'estimatedRoundTripMinutes' | 'estimatedDeliveryDurationMinutes' | 'estimatedSecondsPerStop' | 'dateCreated' | 'dateFinalized' | 'dateCompleted' | 'version'>
    & { orders?: Maybe<Array<(
      Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
      & { privacyZones?: Maybe<Array<(
        Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
        & { center: Pick<Location, 'lat' | 'lng'> }
      )>>, directions?: Maybe<(
        Pick<Directions, 'hashcode'>
        & { legs: Array<(
          Pick<Legs, 'activeLeg'>
          & { steps: Array<Pick<Steps, 'encodedPolyline'>> }
        )> }
      )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
    )>>, startLocation: Pick<Location, 'lat' | 'lng'>, durationMatrix?: Maybe<(
      Pick<DurationMatrix, 'durations'>
      & { destinations: Array<Pick<Location, 'lat' | 'lng'>>, sources: Array<Pick<Location, 'lat' | 'lng'>> }
    )> }
  )> };

export type DeleteDriverRouteMutationVariables = Exact<{
  deviceId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
}>;


export type DeleteDriverRouteMutation = { deleteDriverRoute?: Maybe<(
    Pick<DriverRoute, 'id' | 'deviceId' | 'status' | 'routingType' | 'estimatedRoundTripMinutes' | 'estimatedDeliveryDurationMinutes' | 'estimatedSecondsPerStop' | 'dateCreated' | 'dateFinalized' | 'dateCompleted' | 'version'>
    & { orders?: Maybe<Array<(
      Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
      & { privacyZones?: Maybe<Array<(
        Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
        & { center: Pick<Location, 'lat' | 'lng'> }
      )>>, directions?: Maybe<(
        Pick<Directions, 'hashcode'>
        & { legs: Array<(
          Pick<Legs, 'activeLeg'>
          & { steps: Array<Pick<Steps, 'encodedPolyline'>> }
        )> }
      )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
    )>>, startLocation: Pick<Location, 'lat' | 'lng'>, durationMatrix?: Maybe<(
      Pick<DurationMatrix, 'durations'>
      & { destinations: Array<Pick<Location, 'lat' | 'lng'>>, sources: Array<Pick<Location, 'lat' | 'lng'>> }
    )> }
  )> };

export type CreateDriverOrderMutationVariables = Exact<{
  driverOrderInput?: Maybe<DriverOrderInput>;
}>;


export type CreateDriverOrderMutation = { createDriverOrder?: Maybe<(
    Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
    & { privacyZones?: Maybe<Array<(
      Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
      & { center: Pick<Location, 'lat' | 'lng'> }
    )>>, directions?: Maybe<(
      Pick<Directions, 'hashcode'>
      & { legs: Array<(
        Pick<Legs, 'activeLeg'>
        & { steps: Array<Pick<Steps, 'encodedPolyline'>> }
      )> }
    )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
  )> };

export type DeleteDriverOrderMutationVariables = Exact<{
  orderId?: Maybe<Scalars['String']>;
}>;


export type DeleteDriverOrderMutation = { deleteDriverOrder?: Maybe<(
    Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
    & { privacyZones?: Maybe<Array<(
      Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
      & { center: Pick<Location, 'lat' | 'lng'> }
    )>>, directions?: Maybe<(
      Pick<Directions, 'hashcode'>
      & { legs: Array<(
        Pick<Legs, 'activeLeg'>
        & { steps: Array<Pick<Steps, 'encodedPolyline'>> }
      )> }
    )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
  )> };

export type NotifyDriverOrderUpdatedMutationVariables = Exact<{
  orderId?: Maybe<Scalars['String']>;
}>;


export type NotifyDriverOrderUpdatedMutation = { notifyDriverOrderUpdated?: Maybe<(
    Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
    & { privacyZones?: Maybe<Array<(
      Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
      & { center: Pick<Location, 'lat' | 'lng'> }
    )>>, directions?: Maybe<(
      Pick<Directions, 'hashcode'>
      & { legs: Array<(
        Pick<Legs, 'activeLeg'>
        & { steps: Array<Pick<Steps, 'encodedPolyline'>> }
      )> }
    )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
  )> };

export type UpdateOrderGeofencingEventsMutationVariables = Exact<{
  geofencingInput?: Maybe<DriverOrderGeofencingInput>;
}>;


export type UpdateOrderGeofencingEventsMutation = { updateOrderGeofencingEvents?: Maybe<(
    Pick<DriverOrderGeofenceSubset, 'customerAccessCode'>
    & { geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
  )> };

export type RecordOrderLocationMutationVariables = Exact<{
  orderLocationInput?: Maybe<OrderLocationInput>;
}>;


export type RecordOrderLocationMutation = { recordOrderLocation?: Maybe<(
    Pick<OrderLocation, 'customerAccessCode' | 'eta'>
    & { currentLocation: Pick<OrderLocationPoint, 'lat' | 'lng' | 'timestamp' | 'inPrivacyZoneId'>, previousLocation?: Maybe<Pick<OrderLocationPoint, 'lat' | 'lng' | 'timestamp' | 'inPrivacyZoneId'>> }
  )> };

export type RecordOrderLocationInsidePrivacyZoneMutationVariables = Exact<{
  orderLocationInput?: Maybe<OrderLocationInput>;
}>;


export type RecordOrderLocationInsidePrivacyZoneMutation = { recordOrderLocationInsidePrivacyZone?: Maybe<(
    Pick<OrderLocation, 'customerAccessCode' | 'eta'>
    & { currentLocation: Pick<OrderLocationPoint, 'lat' | 'lng' | 'timestamp' | 'inPrivacyZoneId'>, previousLocation?: Maybe<Pick<OrderLocationPoint, 'lat' | 'lng' | 'timestamp' | 'inPrivacyZoneId'>> }
  )> };

export type GetDriverDeviceQueryVariables = Exact<{
  deviceId?: Maybe<Scalars['String']>;
}>;


export type GetDriverDeviceQuery = { getDriverDevice?: Maybe<(
    Pick<DriverDevice, 'id' | 'dateCreated' | 'operatingSystem' | 'version'>
    & { vendorRegistrations?: Maybe<Array<Pick<VendorRegistration, 'id' | 'vendorId' | 'vendorName' | 'qrCodePayload' | 'registrationDate'>>>, routes?: Maybe<Array<(
      Pick<DriverRoute, 'id' | 'deviceId' | 'status' | 'routingType' | 'estimatedRoundTripMinutes' | 'estimatedDeliveryDurationMinutes' | 'estimatedSecondsPerStop' | 'dateCreated' | 'dateFinalized' | 'dateCompleted' | 'version'>
      & { orders?: Maybe<Array<(
        Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
        & { privacyZones?: Maybe<Array<(
          Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
          & { center: Pick<Location, 'lat' | 'lng'> }
        )>>, directions?: Maybe<(
          Pick<Directions, 'hashcode'>
          & { legs: Array<Pick<Legs, 'activeLeg'>> }
        )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
      )>>, startLocation: Pick<Location, 'lat' | 'lng'>, durationMatrix?: Maybe<(
        Pick<DurationMatrix, 'durations'>
        & { destinations: Array<Pick<Location, 'lat' | 'lng'>>, sources: Array<Pick<Location, 'lat' | 'lng'>> }
      )> }
    )>> }
  )> };

export type GetDriverRouteQueryVariables = Exact<{
  deviceId?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['String']>;
}>;


export type GetDriverRouteQuery = { getDriverRoute?: Maybe<(
    Pick<DriverRoute, 'id' | 'deviceId' | 'status' | 'routingType' | 'estimatedRoundTripMinutes' | 'estimatedDeliveryDurationMinutes' | 'estimatedSecondsPerStop' | 'dateCreated' | 'dateFinalized' | 'dateCompleted' | 'version'>
    & { orders?: Maybe<Array<(
      Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
      & { privacyZones?: Maybe<Array<(
        Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
        & { center: Pick<Location, 'lat' | 'lng'> }
      )>>, directions?: Maybe<(
        Pick<Directions, 'hashcode'>
        & { legs: Array<(
          Pick<Legs, 'activeLeg'>
          & { steps: Array<Pick<Steps, 'encodedPolyline'>> }
        )> }
      )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
    )>>, startLocation: Pick<Location, 'lat' | 'lng'>, durationMatrix?: Maybe<(
      Pick<DurationMatrix, 'durations'>
      & { destinations: Array<Pick<Location, 'lat' | 'lng'>>, sources: Array<Pick<Location, 'lat' | 'lng'>> }
    )> }
  )> };

export type GetDriverOrderQueryVariables = Exact<{
  orderId?: Maybe<Scalars['String']>;
}>;


export type GetDriverOrderQuery = { getDriverOrder?: Maybe<(
    Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
    & { privacyZones?: Maybe<Array<(
      Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
      & { center: Pick<Location, 'lat' | 'lng'> }
    )>>, directions?: Maybe<(
      Pick<Directions, 'hashcode'>
      & { legs: Array<(
        Pick<Legs, 'activeLeg'>
        & { steps: Array<Pick<Steps, 'encodedPolyline'>> }
      )> }
    )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
  )> };

export type GetCustomerOrderQueryVariables = Exact<{
  customerAccessCode?: Maybe<Scalars['String']>;
}>;


export type GetCustomerOrderQuery = { getCustomerOrder?: Maybe<(
    Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
    & { privacyZones?: Maybe<Array<(
      Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
      & { center: Pick<Location, 'lat' | 'lng'> }
    )>>, directions?: Maybe<(
      Pick<Directions, 'hashcode'>
      & { legs: Array<(
        Pick<Legs, 'activeLeg'>
        & { steps: Array<Pick<Steps, 'encodedPolyline'>> }
      )> }
    )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
  )> };

export type GetOrderLocationQueryVariables = Exact<{
  customerAccessCode?: Maybe<Scalars['String']>;
}>;


export type GetOrderLocationQuery = { getOrderLocation?: Maybe<(
    Pick<OrderLocation, 'customerAccessCode' | 'eta'>
    & { currentLocation: Pick<OrderLocationPoint, 'lat' | 'lng' | 'timestamp' | 'inPrivacyZoneId'>, previousLocation?: Maybe<Pick<OrderLocationPoint, 'lat' | 'lng' | 'timestamp' | 'inPrivacyZoneId'>> }
  )> };

export type SubscribeToOrderLocationSubscriptionVariables = Exact<{
  customerAccessCode: Scalars['String'];
}>;


export type SubscribeToOrderLocationSubscription = { subscribeToOrderLocation?: Maybe<(
    Pick<OrderLocation, 'customerAccessCode' | 'eta'>
    & { currentLocation: Pick<OrderLocationPoint, 'lat' | 'lng' | 'timestamp' | 'inPrivacyZoneId'>, previousLocation?: Maybe<Pick<OrderLocationPoint, 'lat' | 'lng' | 'timestamp' | 'inPrivacyZoneId'>> }
  )> };

export type SubscribeDriverOrderSubscriptionVariables = Exact<{
  customerAccessCode?: Maybe<Scalars['String']>;
}>;


export type SubscribeDriverOrderSubscription = { subscribeDriverOrder?: Maybe<(
    Pick<DriverOrder, 'status' | 'cancellationReason' | 'customerAccessCode' | 'routePosition' | 'addressOrganisation' | 'addressFirstLine' | 'addressSecondLine' | 'addressTown' | 'addressLocality' | 'addressPostcode' | 'scanType' | 'vendorId' | 'createdDate' | 'startedDate' | 'finishedDate' | 'version'>
    & { privacyZones?: Maybe<Array<(
      Pick<PrivacyZone, 'id' | 'radiusMetres' | 'transparent' | 'completed'>
      & { center: Pick<Location, 'lat' | 'lng'> }
    )>>, directions?: Maybe<(
      Pick<Directions, 'hashcode'>
      & { legs: Array<(
        Pick<Legs, 'activeLeg'>
        & { steps: Array<Pick<Steps, 'encodedPolyline'>> }
      )> }
    )>, customerLocation: Pick<Location, 'lat' | 'lng'>, vendorLocation: Pick<Location, 'lat' | 'lng'>, geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
  )> };

export type SubscribeToGeofencingUpdatesSubscriptionVariables = Exact<{
  customerAccessCode?: Maybe<Scalars['String']>;
}>;


export type SubscribeToGeofencingUpdatesSubscription = { subscribeToGeofencingUpdates?: Maybe<(
    Pick<DriverOrderGeofenceSubset, 'customerAccessCode'>
    & { geofencingEvents?: Maybe<Array<Pick<GeofencingEvent, 'eventType'>>> }
  )> };
