import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { useState } from "react";
import { resetAppSyncClient } from "../../graphql/services/graphql-client/graphql-client.service";
import { Message } from "../../models/message.model";
import { setLoggingCustomerCode } from "../../services/logging/logging.service";
import {
  removeReconnectListener,
  setupReconnectListener,
} from "../../services/reconnect/reconnect.service";
import { isOrderBeingDelivered } from "../../services/utils.service";
import { HeaderComponent } from "../header/header.component";
import MapComponent from "../map/map.container";
import MessageComponent from "../message/message.container";
import { CustomerCodePropsFromRedux } from "./customer-code.container";

export interface CustomerCodeProps
  extends CustomerCodePropsFromRedux,
    RouteComponentProps {
  customerCode?: string;
}

export const CustomerCodeComponent: React.FC<CustomerCodeProps> = (
  props: CustomerCodeProps
) => {
  const [isListenerActive, setIsListenerActive] = useState<boolean>(false);
  const reconnect = () => {
    if (props.customerCode) {
      resetAppSyncClient();
      props.subscribeToOrderLocation(props.customerCode);
      props.subscribeToOrder(props.customerCode);
      props.subscribeToGeoFenceUpdates(props.customerCode);
      props.fetchOrderDetails(props.customerCode);
      props.fetchOrderLocation(props.customerCode);
    }
  };

  useEffect(() => {
    if (isOrderBeingDelivered(props.orderDetails?.status)) {
      if (!isListenerActive) {
        setupReconnectListener(reconnect);
        setIsListenerActive(true);
      }
    } else {
      if (isListenerActive) {
        removeReconnectListener(reconnect);
      }
    }
  }, [props.orderDetails]);

  useEffect(() => {
    if (props.customerCode) {
      props.updateCustomerCode(props.customerCode);
      setLoggingCustomerCode(props.customerCode);
    } else {
      props.addMessage({
        message: "Delivery Tracking requires a customer code.",
        severity: "error",
      } as Message);
    }
  }, []);

  return (
    <div>
      <HeaderComponent></HeaderComponent>
      <MessageComponent></MessageComponent>
      {props.customerCode && <MapComponent></MapComponent>}
    </div>
  );
};
