import {
  Position,
  LineString,
  nearestPointOnLine,
  Coord,
  distance,
} from "@turf/turf";
import { ThunkDispatch } from "redux-thunk";
import { OrderLocationPoint } from "../../graphql/types";
import {
  UpdateSnappedOrderLocationAction,
  UPDATE_SNAPPED_ORDER_LOCATION,
} from "../../redux/reducers/order-location/order-location.action-type";
import { RootState } from "../../redux/reducers/root.reducer";
import {
  increaseOffRouteCount,
  resetOffRouteCount,
} from "../../redux/reducers/route/route.actions";
import { getConfig } from "../config/config.service";
import { getCoordFromOrderLocationPoint } from "../utils.service";
import { AnyAction } from "redux";

export const getSnappedPoint: (
  orderLocation: Position,
  activeRoute: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[]
) => Position | undefined = (
  orderLocation: Position,
  activeRoute: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[]
) => {
  let closestPoint;
  let distance = 99999;
  activeRoute.forEach(
    (leg: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>) => {
      if ((leg.geometry as LineString).coordinates.length > 0) {
        const nearestPoint = nearestPointOnLine(
          leg.geometry as LineString,
          orderLocation
        );
        if (
          nearestPoint.properties.dist &&
          nearestPoint.properties.dist < distance
        ) {
          distance = nearestPoint.properties.dist;
          closestPoint = nearestPoint.geometry?.coordinates;
        }
      }
    }
  );
  return closestPoint;
};

export const updateSnappedOrderLocation: (
  orderLocation: OrderLocationPoint,
  getState: () => RootState,
  dispatch: ThunkDispatch<RootState, null, AnyAction>
) => void = (
  orderLocation: OrderLocationPoint,
  getState: () => RootState,
  dispatch: ThunkDispatch<RootState, null, AnyAction>
) => {
  const updateSnappedOrderLocation = Object.assign({}, orderLocation);
  const activeRoute = getState().routeReducer.route?.activeRoute;
  if (
    locationNotInPrivacyZone(updateSnappedOrderLocation) &&
    activeRoute &&
    activeRouteHasLocations(activeRoute)
  ) {
    const nearestPoint = getSnappedPoint(
      getCoordFromOrderLocationPoint(updateSnappedOrderLocation),
      activeRoute.features
    );
    if (nearestPoint) {
      if (shouldUseSnappedLocation(updateSnappedOrderLocation, nearestPoint)) {
        updateSnappedOrderLocation.lat = nearestPoint[1];
        updateSnappedOrderLocation.lng = nearestPoint[0];
        dispatch(resetOffRouteCount());
      } else {
        dispatch(increaseOffRouteCount(orderLocation));
      }
    }
  }
  dispatch({
    type: UPDATE_SNAPPED_ORDER_LOCATION,
    payload: updateSnappedOrderLocation,
  } as UpdateSnappedOrderLocationAction);
};

const shouldUseSnappedLocation: (
  orderLocation: OrderLocationPoint,
  snappedCoord: Coord
) => boolean = (orderLocation: OrderLocationPoint, snappedCoord: Coord) => {
  const config = getConfig();
  const dist = distance(
    getCoordFromOrderLocationPoint(orderLocation),
    snappedCoord,
    {
      units: "meters",
    }
  );
  return dist < config.snapToRouteLimitMeters;
};

const activeRouteHasLocations: (
  activeRoute: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  >
) => boolean = (
  activeRoute: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  >
) => {
  let result = false;
  activeRoute.features.forEach((x) => {
    if ((x.geometry as LineString).coordinates?.length > 1) {
      result = true;
    }
  });
  return result;
};

const locationNotInPrivacyZone: (location: OrderLocationPoint) => boolean = (
  location: OrderLocationPoint
) => {
  if (location.inPrivacyZoneId) {
    return false;
  }
  return true;
};
