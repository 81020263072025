import { Message } from "../../../models/message.model";
import {
  ADD_MESSAGE,
  MessageActionTypes,
  REMOVE_MESSAGE,
} from "./message.action-types";

export interface MessagesState {
  messages: Message[];
}
const initialState = {
  messages: [],
} as MessagesState;

export const messagesReducer = (
  state = initialState,
  action: MessageActionTypes
): MessagesState => {
  switch (action.type) {
    case ADD_MESSAGE: {
      const messages = state.messages;
      messages.push(action.payload);
      return {
        ...state,
        messages: [...messages],
      };
    }
    case REMOVE_MESSAGE: {
      const message = state.messages.find(
        (x) =>
          x.message === action.payload.message &&
          x.severity === action.payload.severity
      );
      if (message) {
        const index = state.messages.indexOf(message);
        if (index >= 0) {
          const messages = state.messages;
          messages.splice(index, 1);
          return {
            ...state,
            messages: [...messages],
          };
        }
      }
      return { ...state };
    }
    default:
      return state;
  }
};
