import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import {
  UpdateGeofenceEventsAction,
  UpdateOrderDetailsAction,
  UPDATE_GEOFENCE_EVENTS,
  UPDATE_ORDER_DETAILS,
} from "./order-details.action-type";
import {
  geofenceSubscription,
  getOrderDetails,
  orderSubscription,
} from "../../../graphql/services/order-details/order-details.graphql.service";
import { RootState } from "../root.reducer";
import { Message } from "../../../models/message.model";
import { addMessage } from "../messages/messages.actions";
import {
  DriverOrder,
  DriverOrderGeofenceSubset,
  OrderStatus,
  Query,
  Subscription,
} from "../../../graphql/types";
import {
  convertOrderToRoute,
  updateRoute,
  updateReturnedRoute,
} from "../route/route.actions";
import { GraphQLResult } from "../../../models/graphql-result.model";

let subscription: ZenObservable.Subscription | null = null;

let geoFenceSubscription: ZenObservable.Subscription | null = null;

export const updateOrderDetails: (
  orderDetails?: DriverOrder
) => UpdateOrderDetailsAction = (orderDetails?: DriverOrder) => {
  return {
    type: UPDATE_ORDER_DETAILS,
    payload: orderDetails,
  } as UpdateOrderDetailsAction;
};

export const updateGeofenceEvents: (
  geofenceEvents?: DriverOrderGeofenceSubset
) => UpdateGeofenceEventsAction = (
  geofenceEvents?: DriverOrderGeofenceSubset
) => {
  return {
    type: UPDATE_GEOFENCE_EVENTS,
    payload: geofenceEvents,
  } as UpdateGeofenceEventsAction;
};

export function fetchOrderDetails(customerCode: string) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (
    dispatch: ThunkDispatch<RootState, null, AnyAction>,
    getState: () => RootState
  ) => {
    getOrderDetails(customerCode).then(
      (response: GraphQLResult<Query>) => {
        if (response?.data?.getCustomerOrder) {
          dispatch(updateOrderDetails(response.data.getCustomerOrder));
          dispatchUpdateRoute(
            dispatch,
            getState,
            response.data.getCustomerOrder
          );
        } else if (response?.data?.getCustomerOrder === null) {
          dispatch(
            addMessage({
              message: "Delivery Tracking not found.",
              severity: "error",
            } as Message)
          );
        }
        if (response?.errors) {
          dispatch(
            addMessage({
              message: response.errors[0].message,
              severity: "error",
            } as Message)
          );
        }
      },
      (error: Error) => {
        dispatch(
          addMessage({
            message: error.message,
            severity: "error",
          } as Message)
        );
      }
    );
  };
}

export const unsubscribeOrder: () => void = () => {
  if (subscription) {
    subscription.unsubscribe();
  }
  if (geoFenceSubscription) {
    geoFenceSubscription.unsubscribe();
  }
};

export function subscribeToOrder(customerCode: string) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (
    dispatch: ThunkDispatch<RootState, null, AnyAction>,
    getState: () => RootState
  ) => {
    if (subscription) {
      subscription.unsubscribe();
    }
    subscription = orderSubscription(customerCode).subscribe(
      (response: GraphQLResult<Subscription>) => {
        if (response?.data?.subscribeDriverOrder) {
          dispatch(updateOrderDetails(response.data.subscribeDriverOrder));
          dispatchUpdateRoute(
            dispatch,
            getState,
            response.data.subscribeDriverOrder
          );
        }
        if (response?.errors) {
          dispatch(
            addMessage({
              message: response.errors[0].message,
              severity: "error",
            } as Message)
          );
        }
      },
      (error: Error) => {
        dispatch(
          addMessage({
            message: error.message,
            severity: "error",
          } as Message)
        );
      }
    );
  };
}

export function subscribeToGeoFenceUpdates(customerCode: string) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (dispatch: ThunkDispatch<RootState, null, AnyAction>) => {
    if (geoFenceSubscription) {
      geoFenceSubscription.unsubscribe();
    }
    geoFenceSubscription = geofenceSubscription(customerCode).subscribe(
      (response: GraphQLResult<Subscription>) => {
        if (response?.data?.subscribeToGeofencingUpdates) {
          dispatch(
            updateGeofenceEvents(response.data.subscribeToGeofencingUpdates)
          );
        }
        if (response?.errors) {
          dispatch(
            addMessage({
              message: response.errors[0].message,
              severity: "error",
            } as Message)
          );
        }
      },
      (error: Error) => {
        dispatch(
          addMessage({
            message: error.message,
            severity: "error",
          } as Message)
        );
      }
    );
  };
}

const dispatchUpdateRoute: (
  dispatch: ThunkDispatch<RootState, null, AnyAction>,
  getState: () => RootState,
  driverOrder: DriverOrder
) => void = (
  dispatch: ThunkDispatch<RootState, null, AnyAction>,
  getState: () => RootState,
  driverOrder: DriverOrder
) => {
  const state = getState();
  const previousHash = state.routeReducer.route?.hashcode;
  if (
    driverOrder.status === OrderStatus.Started &&
    previousHash !== driverOrder.directions?.hashcode
  ) {
    const route = convertOrderToRoute(
      driverOrder,
      state.orderLocationReducer.snappedOrderLocation
    );
    dispatch(updateRoute(route));
    dispatch(updateReturnedRoute(route));
  }
};
