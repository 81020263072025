import React, { useEffect, useState } from "react";
import { RoutePropsFromRedux } from "./route.container";
import mapboxgl, { LngLat } from "mapbox-gl";
import { CustomerRoute } from "../../models/route.model";
import { Location } from "../../graphql/types";
import {
  boundMaptoCoordinates,
  createOrUpdateExistingLayer,
  getCoordFromLngLat,
} from "../../services/utils.service";
import { LineString, Position } from "@turf/turf";

import { Geometry, Feature } from "geojson";
import { updateActiveRouteOnMap } from "./route.service";
import { showPZ } from "../privacy-zone/privacy-zone.component";
import { getNESWPointFromCoordAndDistance } from "../privacy-zone/privacy-zone.service";

export interface RouteProps extends RoutePropsFromRedux {
  map: mapboxgl.Map;
}
export const RouteComponent: React.FC<RouteProps> = (props: RouteProps) => {
  const [shouldFastBound, setShouldFastBound] = useState<boolean>(true);
  useEffect(() => {
    if (props.route && props.map) {
      drawRoute(props.route, props.map);
    }
  }, [props.route]);

  useEffect(() => {
    boundMap();
  }, [props.boundMapOnLocationUpdate, props.snappedLocationPoint]);

  const boundMap = () => {
    if (props.boundMapOnLocationUpdate && props.route?.activeRoute) {
      boundMaptoCoordinates(
        getCoordinateArrayFromRoute(props.route, props.customerLocation),
        props.map,
        shouldFastBound
      );
      if (shouldFastBound) {
        setShouldFastBound(false);
      }
    } else if (!props.boundMapOnLocationUpdate) {
      setShouldFastBound(true);
    }
  };

  const drawRoute: (route: CustomerRoute, map: mapboxgl.Map) => void = (
    route: CustomerRoute,
    map: mapboxgl.Map
  ) => {
    if (route.activeRoute) {
      createOrUpdateExistingLayer(map, {
        id: "route",
        type: "line",
        source: {
          type: "geojson",
          data: route.subsequentRoute,
        },
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#222",
          "line-width": 5,
          "line-opacity": 0.3,
        },
      });

      updateActiveRouteOnMap(route.activeRoute, map);
    }
  };

  const getCoordinatesfromFeatures = (
    features: Feature<Geometry, GeoJSON.GeoJsonProperties>[]
  ) => {
    let result = [] as Position[];
    features.forEach((feature) => {
      result = result.concat(
        returnArrayFromLineString(
          feature as Feature<Geometry, GeoJSON.GeoJsonProperties>
        )
      );
    });
    return result;
  };

  const getCoordinateArrayFromRoute: (
    route: CustomerRoute,
    customerLocation: Location | undefined
  ) => [number, number][] = (
    route: CustomerRoute,
    customerLocation: Location | undefined
  ) => {
    let arrayCoordinates = [] as Position[];
    arrayCoordinates = arrayCoordinates.concat(
      getCoordinatesfromFeatures(
        route.activeRoute.features as Feature<
          Geometry,
          GeoJSON.GeoJsonProperties
        >[]
      )
    );
    arrayCoordinates = arrayCoordinates.concat(
      getCoordinatesfromFeatures(
        route.subsequentRoute.features as Feature<
          Geometry,
          GeoJSON.GeoJsonProperties
        >[]
      )
    );
    arrayCoordinates = arrayCoordinates.concat(getActivePZBoundCoords());
    if (customerLocation) {
      arrayCoordinates.push(getCoordFromLngLat(customerLocation as LngLat));
    }
    return arrayCoordinates as [number, number][];
  };

  const getActivePZBoundCoords = () => {
    const activePZs =
      props.route?.privacyZones?.filter((x) => {
        return showPZ(
          x,
          props.snappedLocationPoint?.inPrivacyZoneId,
          props.route?.activeRoute,
          props.route?.subsequentRoute
        );
      }) ?? [];
    return activePZs.flatMap((pz) =>
      getNESWPointFromCoordAndDistance(
        getCoordFromLngLat(pz.center as LngLat),
        pz.radiusMetres
      )
    );
  };

  const returnArrayFromLineString: (
    feature: Feature<Geometry, GeoJSON.GeoJsonProperties>
  ) => Position[] = (feature: Feature<Geometry, GeoJSON.GeoJsonProperties>) => {
    return (feature.geometry as LineString).coordinates as Position[];
  };

  // for void component
  return null;
};
