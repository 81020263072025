import { OrderLocation, OrderLocationPoint } from "../../../graphql/types";
import {
  OrderLocationActionTypes,
  UPDATE_ORDER_LOCATION,
  UPDATE_SNAPPED_ORDER_LOCATION,
} from "./order-location.action-type";

export interface OrderLocationState {
  snappedOrderLocation: OrderLocationPoint | null;
  orderLocation: OrderLocation | null;
}
const initialState = {
  orderLocation: null,
  snappedOrderLocation: null,
} as OrderLocationState;

export const orderLocationReducer = (
  state = initialState,
  action: OrderLocationActionTypes
): OrderLocationState => {
  switch (action.type) {
    case UPDATE_ORDER_LOCATION: {
      return {
        ...state,
        orderLocation: action.payload,
      };
    }
    case UPDATE_SNAPPED_ORDER_LOCATION: {
      return {
        ...state,
        snappedOrderLocation: action.payload,
      };
    }
    default:
      return state;
  }
};
