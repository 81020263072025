import React, { useEffect, useRef, useState } from "react";
import mapboxgl, { LngLat, Marker } from "mapbox-gl";
import { updateMarkerPoint } from "../../services/marker/marker.service";
import privacyZoneMarkerIcon from "../../images/privacy_zone_icon.svg";
import privacyZoneCompleteMarkerIcon from "../../images/privacy_zone_complete_icon.svg";
import { circle, pointToLineDistance } from "@turf/turf";
import {
  createOrUpdateExistingLayer,
  getCoordFromLngLat,
} from "../../services/utils.service";
import { PrivacyZone } from "../../graphql/types";
import { PrivacyZonePropsFromRedux } from "./privacy-zone.container";
import { makeStyles } from "@material-ui/core";
import { getConfig } from "../../services/config/config.service";

export interface PrivacyZoneProps extends PrivacyZonePropsFromRedux {
  map: mapboxgl.Map;
  privacyZone: PrivacyZone;
  key: number;
}

const useStyles = makeStyles(() => ({
  privacyZone: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "40px",
    height: "40px",
    position: "absolute",
  },
  privacyZoneMarker: {
    backgroundImage: `url(${privacyZoneMarkerIcon})`,
  },
  privacyZoneMarkerCompleted: {
    backgroundImage: `url(${privacyZoneCompleteMarkerIcon})`,
  },
  hidePrivacyZone: {
    display: "none",
  },
}));

export const PrivacyZoneComponent: React.FC<PrivacyZoneProps> = (
  props: PrivacyZoneProps
) => {
  const markerContainerRef = useRef<HTMLDivElement>(null);
  const [marker, setMarker] = useState<Marker | null>(null);
  const [pzCreated, setPzCreated] = useState<boolean>(false);
  const [previousShow, setPreviousShow] = useState<boolean | null>(null);
  const classes = useStyles();

  const createMarker: (markerElement: HTMLElement, show: boolean) => void = (
    markerElement: HTMLElement,
    show: boolean
  ) => {
    const privacyZoneMarker = new Marker(markerElement);

    updateMarkerPoint(
      getCoordFromLngLat(props.privacyZone.center as LngLat),
      privacyZoneMarker
    );
    setMarker(privacyZoneMarker);
    if (show) {
      marker?.addTo(props.map);
    }
  };

  const getPrivacyZoneClass = (show: boolean) => {
    return `${classes.privacyZone} 
    ${
      props.privacyZone.completed
        ? classes.privacyZoneMarkerCompleted + " privacyZoneMarkerCompleted"
        : classes.privacyZoneMarker + " privacyZoneMarker"
    }
    ${show ? "" : classes.hidePrivacyZone + " hidePrivacyZone"}
  `;
  };

  const createGeoCircle: (show: boolean) => void = (show: boolean) => {
    if (!props.privacyZone.transparent) {
      const circ = circle(
        getCoordFromLngLat(props.privacyZone.center as LngLat),
        props.privacyZone.radiusMetres,
        { steps: 512, units: "meters" }
      );
      if (circ) {
        createOrUpdateExistingLayer(props.map, {
          id: props.privacyZone.id,
          type: "fill",
          layout: {
            visibility: show ? "visible" : "none",
          },
          source: {
            type: "geojson",
            data: circ as GeoJSON.Feature<
              GeoJSON.Geometry,
              GeoJSON.GeoJsonProperties
            >,
          },
          paint: {
            "fill-opacity": 0.5,
            "fill-color": "#000",
          },
        });
      }
    }
  };

  const updatePZVisibility = (show: boolean) => {
    if (props.map.getSource(props.privacyZone.id)) {
      props.map.setLayoutProperty(
        props.privacyZone.id,
        "visibility",
        show ? "visible" : "none"
      );
    }
    if (marker) {
      updateMarker(marker, show);
    }
  };

  const setPZClasses = (show: boolean) => {
    if (markerContainerRef.current) {
      markerContainerRef.current.className = getPrivacyZoneClass(show);
    }
  };

  const updateMarker = (pzMarker: Marker, show: boolean) => {
    if (show) {
      pzMarker.addTo(props.map);
    } else {
      pzMarker.remove();
    }
  };

  useEffect(() => {
    const show = showPZ(
      props.privacyZone,
      props.inPrivacyZoneId,
      props.activeRoute,
      props.subsequentRoute
    );
    if (show !== previousShow && pzCreated) {
      updatePZVisibility(show);
      setPreviousShow(show);
    }
    setPZClasses(show);
  }, [
    props.inPrivacyZoneId,
    props.privacyZone.completed,
    props.activeRoute,
    props.subsequentRoute,
    props.snappedLocation,
    props.map,
    marker,
  ]);

  useEffect(() => {
    if (markerContainerRef?.current !== void 0) {
      const show = showPZ(
        props.privacyZone,
        props.inPrivacyZoneId,
        props.activeRoute,
        props.subsequentRoute
      );
      createMarker(markerContainerRef.current as HTMLElement, show);
      createGeoCircle(show);
      setPzCreated(true);
    }
  }, []);

  return (
    <div ref={markerContainerRef}>
      <div
        className={
          props.inPrivacyZoneId === props.privacyZone.id ? "animating" : ""
        }
      ></div>
    </div>
  );
};

export const routeTraversesPZ: (
  activeRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined,
  subsequentRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined,
  privacyZone: PrivacyZone
) => boolean = (
  activeRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined,
  subsequentRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined,
  privacyZone: PrivacyZone
) => {
  let touchPoints = 0;
  const point = getCoordFromLngLat(privacyZone.center as LngLat);
  activeRoute?.features.forEach((feature) => {
    const result = pointToLineDistance(point, feature, {
      units: "meters",
    });
    if (
      result <
      privacyZone.radiusMetres + getConfig().privacyZoneTouchRadiusMeters
    ) {
      touchPoints++;
    }
  });
  subsequentRoute?.features.forEach((feature) => {
    if (
      pointToLineDistance(point, feature, {
        units: "meters",
      }) <
      privacyZone.radiusMetres + 10
    ) {
      touchPoints++;
    }
  });
  return touchPoints > 0; // only clear the PZ's with no touchpoints
};

export const showPZ: (
  privacyZone: PrivacyZone,
  inPrivacyZoneId: string | undefined | null,
  activeRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined,
  subsequentRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined
) => boolean = (
  privacyZone: PrivacyZone,
  inPrivacyZoneId: string | undefined | null,
  activeRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined,
  subsequentRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined
) => {
  return (
    !privacyZone.completed ||
    inPrivacyZoneId === privacyZone.id ||
    routeTraversesPZ(activeRoute, subsequentRoute, privacyZone)
  );
};
