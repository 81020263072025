import { DriverOrder, DriverOrderGeofenceSubset } from "../../../graphql/types";

export const UPDATE_ORDER_DETAILS = "UPDATE_ORDER_DETAILS";
export const UPDATE_GEOFENCE_EVENTS = "UPDATE_GEOFENCE_EVENTS";

export interface UpdateOrderDetailsAction {
  type: typeof UPDATE_ORDER_DETAILS;
  payload: DriverOrder;
}

export interface UpdateGeofenceEventsAction {
  type: typeof UPDATE_GEOFENCE_EVENTS;
  payload: DriverOrderGeofenceSubset;
}

export type OrderDetailsActionTypes =
  | UpdateOrderDetailsAction
  | UpdateGeofenceEventsAction;
