import { removeTraversedRoute } from "../../../components/route/route.service";
import { CustomerRoute } from "../../../models/route.model";
import { getCoordFromOrderLocationPoint } from "../../../services/utils.service";
import {
  INCREASE_OFF_ROUTE_COUNT,
  RESET_OFF_ROUTE_COUNT,
  RouteActionTypes,
  UPDATE_RETURNED_ROUTE,
  UPDATE_ROUTE,
} from "./route.action-types";

export interface RouteState {
  route: CustomerRoute | null;
  returnedRoute: CustomerRoute | null;
  offRouteCount: number;
}
const initialState = {
  route: null,
  returnedRoute: null,
  offRouteCount: 0,
} as RouteState;

export const routeReducer = (
  state = initialState,
  action: RouteActionTypes
): RouteState => {
  switch (action.type) {
    case UPDATE_ROUTE: {
      return {
        ...state,
        route: Object.assign({}, action.payload),
      };
    }
    case UPDATE_RETURNED_ROUTE: {
      return {
        ...state,
        returnedRoute: JSON.parse(JSON.stringify(action.payload)),
      };
    }
    case INCREASE_OFF_ROUTE_COUNT: {
      const result = {
        ...state,
        offRouteCount: state.offRouteCount + 1,
      };
      if (state.offRouteCount >= 5) {
        result.route = JSON.parse(JSON.stringify(state.returnedRoute));
        removeTraversedRoute(
          result.route?.activeRoute,
          getCoordFromOrderLocationPoint(action.payload)
        );
      }
      return result;
    }
    case RESET_OFF_ROUTE_COUNT: {
      return { ...state, offRouteCount: 0 };
    }
    default:
      return state;
  }
};
