import {
  OrderLocation,
  OrderLocationPoint,
  Query,
  Subscription,
} from "../../../graphql/types";
import {
  UpdateOrderLocationAction,
  UPDATE_ORDER_LOCATION,
} from "./order-location.action-type";
import { ZenObservable } from "zen-observable-ts";
import { RootState } from "../root.reducer";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  getOrderLocation,
  orderLocationSubscription,
} from "../../../graphql/services/location/location.graphql.service";
import { Message } from "../../../models/message.model";
import { addMessage } from "../messages/messages.actions";
import { GraphQLResult } from "../../../models/graphql-result.model";

import { updateSnappedOrderLocation } from "../../../services/snapped-point/snapped-point.service";

let subscription: ZenObservable.Subscription | null = null;

export const updateOrderLocation: (
  orderLocation: OrderLocation
) => UpdateOrderLocationAction = (orderLocation: OrderLocation) => {
  return {
    type: UPDATE_ORDER_LOCATION,
    payload: orderLocation,
  } as UpdateOrderLocationAction;
};

export const unsubscribeOrderLocation: () => void = () => {
  if (subscription) {
    subscription.unsubscribe();
  }
};

export const fetchOrderLocation: (customerCode: string) => void = (
  customerCode: string
) => {
  return async (
    dispatch: ThunkDispatch<RootState, null, AnyAction>,
    getState: () => RootState
  ) => {
    getOrderLocation(customerCode).then(
      (response: GraphQLResult<Query>) => {
        updateLocations(response?.data?.getOrderLocation, getState, dispatch);
        if (response?.errors) {
          dispatch(
            addMessage({
              message: response.errors[0].message,
              severity: "error",
            } as Message)
          );
        }
      },
      (error: Error) => {
        dispatch(
          addMessage({
            message: error.message,
            severity: "error",
          } as Message)
        );
      }
    );
  };
};

export function subscribeToOrderLocation(customerCode: string) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (
    dispatch: ThunkDispatch<RootState, null, AnyAction>,
    getState: () => RootState
  ) => {
    if (subscription) {
      subscription.unsubscribe();
    }
    subscription = orderLocationSubscription(customerCode).subscribe(
      (result: GraphQLResult<Subscription>) => {
        updateLocations(
          result?.data?.subscribeToOrderLocation,
          getState,
          dispatch
        );
        if (result.errors) {
          dispatch(
            addMessage({
              message: result.errors[0].message,
              severity: "error",
            } as Message)
          );
        }
      },
      (error: Error) => {
        dispatch(
          addMessage({
            message: error.message,
            severity: "error",
          } as Message)
        );
      }
    );
  };
}

const updateLocations = (
  updatedLocation: OrderLocation | undefined | null,
  getState: () => RootState,
  dispatch: ThunkDispatch<RootState, null, AnyAction>
) => {
  if (
    updatedLocation &&
    isLocationDifferentToPreviousUpdate(
      getState,
      updatedLocation.currentLocation
    )
  ) {
    dispatch(updateOrderLocation(updatedLocation));
    updateSnappedOrderLocation(
      updatedLocation.currentLocation,
      getState,
      dispatch
    );
  }
};

const isLocationDifferentToPreviousUpdate: (
  getState: () => RootState,
  latestUpdate: OrderLocationPoint
) => boolean = (
  getState: () => RootState,
  latestUpdate: OrderLocationPoint
) => {
  const previousUpdate = getState().orderLocationReducer.orderLocation
    ?.currentLocation;
  if (previousUpdate && latestUpdate && !latestUpdate.inPrivacyZoneId) {
    return (
      previousUpdate.lat !== latestUpdate.lat ||
      previousUpdate.lng !== latestUpdate.lng
    );
  }
  return true;
};
