import React, { useEffect, useState } from "react";
import {
  GeofencingEvent,
  GeofencingEventType,
  OrderStatus,
} from "../../graphql/types";
import { Message } from "../../models/message.model";
import { OrderStatusMessagesPropsFromRedux } from "./order-status-messages.container";

export const OrderStatusMessagesComponent: React.FC<OrderStatusMessagesPropsFromRedux> = (
  props: OrderStatusMessagesPropsFromRedux
) => {
  const [hasNotifiedNearby, setHasNotifiedNearby] = useState<boolean>(false);
  const nearbyMessage = {
    message: "Your order is nearby.",
    severity: "info",
  } as Message;
  useEffect(() => {
    switch (props.orderStatus) {
      case OrderStatus.Cancelled: {
        removeNearbyMessage();
        props.addMessage({
          message: mapCancellationReasonToMessage(
            props.orderNotDeliveredReason
          ),
          severity: "error",
        } as Message);
        break;
      }
      case OrderStatus.Completed: {
        removeNearbyMessage();
        props.addMessage({
          message: "Your order has been delivered.",
          severity: "success",
        });
        break;
      }
      case OrderStatus.NotStarted: {
        props.addMessage({
          message: "Your order has not been dispatched yet.",
          severity: "info",
        });
        break;
      }
      default:
        break;
    }
  }, [props.orderStatus]);

  useEffect(() => {
    if (
      !hasNotifiedNearby &&
      props.orderGeofenceEvents &&
      props.orderStatus === OrderStatus.Started &&
      eventsContainNearby(props.orderGeofenceEvents)
    ) {
      setHasNotifiedNearby(true);
      props.addMessage(nearbyMessage);
    }
  }, [props.orderGeofenceEvents]);

  const mapCancellationReasonToMessage = (
    cancellationReason?: string | null
  ) => {
    switch (cancellationReason) {
      case "NOT_ABLE_TO_LOCATE": {
        return "The driver was unable to find your address.";
      }
      case "NOT_ABLE_TO_CONTACT": {
        return "The driver was unable to contact you about your delivery.";
      }
      case "CUSTOMER_REJECTED": {
        return "You didn’t accept the delivery.";
      }
      default:
        return "The driver didn’t manage to deliver your order.";
    }
  };

  const eventsContainNearby: (events: GeofencingEvent[]) => boolean = (
    events: GeofencingEvent[]
  ) => {
    return (
      events.filter(
        (event: GeofencingEvent) =>
          event.eventType === GeofencingEventType.OrderNearby
      ).length > 0
    );
  };

  const removeNearbyMessage = () => props.removeMessage(nearbyMessage);

  // for void component
  return null;
};
