import {
  CustomerCodeActionTypes,
  UPDATE_CUSTOMER_CODE,
} from "./customer-code.action-type";

export interface CustomerCodeState {
  customerCode: string | null;
}
const initialState = {
  customerCode: null,
} as CustomerCodeState;

export const customerCodeReducer = (
  state = initialState,
  action: CustomerCodeActionTypes
): CustomerCodeState => {
  switch (action.type) {
    case UPDATE_CUSTOMER_CODE: {
      return {
        ...state,
        customerCode: action.payload,
      };
    }
    default:
      return state;
  }
};
