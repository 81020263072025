import {
  DISABLE_BOUND_MAP_ON_LOCATION,
  ENABLE_BOUND_MAP_ON_LOCATION,
  MapActionTypes,
} from "./map.action-types";

export interface MapState {
  boundMapOnLocationUpdate: boolean;
}
const initialState = {
  boundMapOnLocationUpdate: true,
} as MapState;

export const mapReducer = (
  state = initialState,
  action: MapActionTypes
): MapState => {
  switch (action.type) {
    case ENABLE_BOUND_MAP_ON_LOCATION: {
      return {
        ...state,
        boundMapOnLocationUpdate: true,
      };
    }
    case DISABLE_BOUND_MAP_ON_LOCATION: {
      return {
        ...state,
        boundMapOnLocationUpdate: false,
      };
    }
    default:
      return state;
  }
};
