import { AppBar, makeStyles, Toolbar } from "@material-ui/core";
import React, { useState } from "react";
import icon from "../../images/logos-red-wordmark-icon.svg";
import { getConfig } from "../../services/config/config.service";
import EtaComponent from "../eta/eta.container";

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: 160,
  },
  hidden: {
    display: "none",
  },
  buildDate: {
    fontSize: "95%",
    lineHeight: "10px",
  },
}));

export const HeaderComponent: React.FC = () => {
  const classes = useStyles();
  const [clickCount, setClickCount] = useState<number>(0);
  const buildDate = getConfig().buildDate;

  const click = () => {
    setClickCount(clickCount + 1);
  };

  return (
    <div>
      <AppBar position="static">
        <div className={clickCount > 5 ? classes.buildDate : classes.hidden}>
          {buildDate}
        </div>
        <Toolbar>
          <img src={icon} alt="logo" className={classes.logo} onClick={click} />
          <EtaComponent></EtaComponent>
        </Toolbar>
      </AppBar>
    </div>
  );
};
