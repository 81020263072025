import { connect, ConnectedProps } from "react-redux";
import MapComponent from "./map.component";
import { RootState } from "../../redux/reducers/root.reducer";
import {
  fetchOrderDetails,
  subscribeToOrder,
  subscribeToGeoFenceUpdates,
} from "../../redux/reducers/order-details/order-details.actions";
import {
  subscribeToOrderLocation,
  fetchOrderLocation,
} from "../../redux/reducers/order-location/order-location.actions";
import {
  disableBoundMapOnLocation,
  enableBoundMapOnLocation,
} from "../../redux/reducers/map/map.actions";

const mapState = (state: RootState) => ({
  customerCode: state.customerCodeReducer.customerCode,
  orderDetails: state.orderDetailsReducer.orderDetails,
  snappedLocation: state.orderLocationReducer.snappedOrderLocation,
  route: state.routeReducer.route,
  boundMapOnLocation: state.mapReducer.boundMapOnLocationUpdate,
});

const mapDispatch = {
  fetchOrderDetails,
  subscribeToOrder,
  fetchOrderLocation,
  subscribeToOrderLocation,
  subscribeToGeoFenceUpdates,
  disableBoundMapOnLocation,
  enableBoundMapOnLocation,
};

const connector = connect(mapState, mapDispatch);

export type MapPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MapComponent);
