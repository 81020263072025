export const getVisibilityChangePropertyName: () => string | null = () => {
  return getBrowserSpecificDocumentPropertyNamesFromMDNString(
    "visibilitychange",
    document
  );
};

export const getHiddenPropertyName: () => string | null = () => {
  return getBrowserSpecificDocumentPropertyNamesFromMDNString(
    "hidden",
    document
  );
};

export const getBrowserSpecificDocumentPropertyNamesFromMDNString: (
  suffix: string,
  document: Document
) => string | null = (suffix: string, document: Document) => {
  // if MDN property exists use MDN string
  if ("hidden" in document) {
    return suffix;
  }

  // if document contains browser specific properties then return the found property
  const prefixes = ["moz", "ms", "o", "webkit"];
  for (let i = 0; i < prefixes.length; i++) {
    // build the property string, check for hidden to use upper case H
    const testPrefix = prefixes[i] + (suffix === "hidden" ? "Hidden" : suffix);
    if (testPrefix in document) {
      return testPrefix;
    }
  }
  // no property found cannot detect whether the document is visible
  return null;
};
