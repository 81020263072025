import { createStyles, Fab, makeStyles } from "@material-ui/core";
import mapboxgl, { LngLat, Map } from "mapbox-gl";

import React, { useEffect, useRef, useState } from "react";
import {
  createVendorMarker,
  getDestinationMarker,
} from "../../services/marker/marker.service";

import CarComponent from "../car/car.container";
import MarkerComponent from "../marker/marker.component";
import PrivacyZoneComponent from "../privacy-zone/privacy-zone.container";
import RouteComponent from "../route/route.container";
import { MapPropsFromRedux } from "./map.container";
import DriverConnectionWarningComponent from "../driver-connection-warning/driver-connection-warning.container";
import OrderStatusMessagesComponent from "../order-status-messages/order-status-messages.container";
import { unsubscribeOrderLocation } from "../../redux/reducers/order-location/order-location.actions";
import { unsubscribeOrder } from "../../redux/reducers/order-details/order-details.actions";
import { setCarAnnimationOff } from "../car/car.service";
import {
  getCoordFromLngLat,
  isOrderBeingDelivered,
} from "../../services/utils.service";

const useStyles = makeStyles(() =>
  createStyles({
    fab: {
      position: "absolute",
      bottom: "20px",
      right: "20px",
      zIndex: 1,
    },
  })
);

export const MapComponent: React.FC<MapPropsFromRedux> = (
  props: MapPropsFromRedux
) => {
  const classes = useStyles();
  const [map, setMap] = useState<Map | null>(null);
  const [mapPriorToLoading, setMapPriorToLoading] = useState<Map | null>(null);
  const [mapRemoved, setMapRemoved] = useState<boolean>(false);
  const [
    hasSubscribedToLocation,
    setHasSubscribedToLocation,
  ] = useState<boolean>(false);
  const [hasSubscribedToClick, setHasSubscribedToClick] = useState<boolean>(
    false
  );
  const mapContainerRef = useRef(null);

  useEffect(() => {
    if (props.orderDetails) {
      if (
        isOrderBeingDelivered(props.orderDetails.status) &&
        props.orderDetails?.customerLocation &&
        map === null
      ) {
        initMapAndSubscriptions(props.orderDetails.customerLocation as LngLat);
      }
      if (
        !isOrderBeingDelivered(props.orderDetails.status) &&
        map &&
        !mapRemoved
      ) {
        setMapRemoved(true);
        cancelSubscriptionsAndRemoveMap(map);
      }
    }
  }, [props.orderDetails]);

  useEffect(() => {
    if (mapPriorToLoading && props.customerCode !== null) {
      mapPriorToLoading.on("load", () => {
        mapPriorToLoading.dragRotate.disable();
        mapPriorToLoading.touchZoomRotate.disableRotation();
        setMap(mapPriorToLoading);
      });
    }
  }, [mapPriorToLoading, props.customerCode]);

  useEffect(() => {
    if (!hasSubscribedToClick && map) {
      const triggers = ["mousedown", "touchstart", "wheel", "drag", "pitch"];
      triggers.forEach((trigger) => {
        map.on(trigger, () => {
          props.disableBoundMapOnLocation();
        });
      });
      setHasSubscribedToClick(true);
    }
  }, [map]);

  useEffect(() => {
    if (map && props.route?.activeRoute && !hasSubscribedToLocation) {
      props.subscribeToOrderLocation(props.customerCode as string);
      props.subscribeToGeoFenceUpdates(props.customerCode as string);
      setHasSubscribedToLocation(true);
    }
  }, [map, props.route]);

  useEffect(() => {
    if (props.customerCode) {
      props.fetchOrderLocation(props.customerCode as string);
    }
  }, [props.customerCode]);

  useEffect(() => {
    if (props.snappedLocation) {
      props.fetchOrderDetails(props.customerCode as string);
    }
  }, [props.snappedLocation]);

  const initMapAndSubscriptions: (customerLocation: LngLat) => void = (
    customerLocation: LngLat
  ) => {
    setMapPriorToLoading(
      createMap(mapContainerRef, getCoordFromLngLat(customerLocation))
    );
    props.subscribeToOrder(props.customerCode as string);
  };

  const cancelSubscriptionsAndRemoveMap: (map: Map) => void = (map: Map) => {
    setCarAnnimationOff();
    unsubscribeOrderLocation();
    unsubscribeOrder();
    map.remove();
    props.enableBoundMapOnLocation();
  };

  const drawCar = () => {
    return map !== null && <CarComponent map={map}></CarComponent>;
  };

  const drawDestination = () => {
    if (
      props.orderDetails?.customerLocation?.lat &&
      props.orderDetails?.customerLocation?.lng &&
      map
    ) {
      const destinationMarker = getDestinationMarker([
        props.orderDetails.customerLocation.lng,
        props.orderDetails.customerLocation.lat,
      ]);
      return (
        <MarkerComponent map={map} marker={destinationMarker}></MarkerComponent>
      );
    }
  };

  const drawStart = () => {
    if (
      props.orderDetails?.vendorLocation?.lat &&
      props.orderDetails?.vendorLocation?.lng &&
      map
    ) {
      const destinationMarker = createVendorMarker([
        props.orderDetails.vendorLocation.lng,
        props.orderDetails.vendorLocation.lat,
      ]);
      return (
        <MarkerComponent map={map} marker={destinationMarker}></MarkerComponent>
      );
    }
  };

  const drawPrivacyZones = () => {
    if (map !== null && props.route?.privacyZones) {
      return (
        <div>
          {props.route.privacyZones.map((value, key) => {
            return (
              <PrivacyZoneComponent
                key={key}
                map={map}
                privacyZone={Object.assign({}, value)}
              ></PrivacyZoneComponent>
            );
          })}
        </div>
      );
    }
  };
  const drawRoute = () => {
    if (map) {
      return <RouteComponent map={map}></RouteComponent>;
    }
  };

  const drawTrackCarButton = () => {
    if (!props.boundMapOnLocation) {
      return (
        <div>
          <Fab
            onClick={props.enableBoundMapOnLocation}
            aria-label="Track Car"
            className={classes.fab}
          >
            {"Track"}
          </Fab>
        </div>
      );
    }
  };

  const drawOrderStatusMessage = () => {
    return <OrderStatusMessagesComponent></OrderStatusMessagesComponent>;
  };

  const drawConnectionWarning = () => {
    return (
      <DriverConnectionWarningComponent></DriverConnectionWarningComponent>
    );
  };

  return (
    <div className="map-container disable-select" ref={mapContainerRef}>
      {drawCar()}
      {drawDestination()}
      {drawStart()}
      {drawPrivacyZones()}
      {drawRoute()}
      {drawTrackCarButton()}
      {drawConnectionWarning()}
      {drawOrderStatusMessage()}
    </div>
  );
};

const createMap: (
  mapContainerRef: React.MutableRefObject<null>,
  coords: [number, number]
) => mapboxgl.Map = (
  mapContainerRef: React.MutableRefObject<null>,
  coords: [number, number]
) => {
  const map = new mapboxgl.Map({
    container: mapContainerRef.current ?? "",
    style: "mapbox://styles/mapbox/streets-v11",
    center: coords,
    zoom: 12,
    maxZoom: 18,
  });
  return map;
};

export default MapComponent;
