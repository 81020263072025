import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import { updateMarkerPoint } from "../../services/marker/marker.service";
import { MarkerModel } from "../../models/marker.model";

export interface MarkerProps {
  map: mapboxgl.Map;
  marker: MarkerModel;
}

const MarkerComponent: React.FC<MarkerProps> = (props: MarkerProps) => {
  const markerContainerRef = useRef<HTMLDivElement>(null);

  const createMarker: (markerElement: HTMLElement) => void = (
    markerElement: HTMLElement
  ) => {
    markerElement.style.backgroundImage = `url(${props.marker.markerImage})`;
    markerElement.style.backgroundRepeat = "no-repeat";
    markerElement.style.backgroundSize = "cover";
    markerElement.style.width = props.marker.width;
    markerElement.style.height = props.marker.height;

    const marker = new mapboxgl.Marker(markerElement);

    updateMarkerPoint(props.marker.position, marker);
    marker.addTo(props.map);
  };

  useEffect(() => {
    if (markerContainerRef?.current !== void 0) {
      createMarker(markerContainerRef.current as HTMLElement);
    }
  }, []);

  return <div className="marker" ref={markerContainerRef} />;
};

export default MarkerComponent;
