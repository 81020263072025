import { OrderLocationPoint } from "../../../graphql/types";
import { CustomerRoute } from "../../../models/route.model";

export const UPDATE_ROUTE = "UPDATE_ROUTE";
export const UPDATE_RETURNED_ROUTE = "UPDATE_RETURNED_ROUTE";
export const RESET_OFF_ROUTE_COUNT = "RESET_OFF_ROUTE_COUNT";
export const INCREASE_OFF_ROUTE_COUNT = "INCREASE_OFF_ROUTE_COUNT";

export interface ResetOffRouteCountAction {
  type: typeof RESET_OFF_ROUTE_COUNT;
}

export interface IncreaseOffRouteCountAction {
  type: typeof INCREASE_OFF_ROUTE_COUNT;
  payload: OrderLocationPoint;
}
export interface UpdateRouteAction {
  type: typeof UPDATE_ROUTE;
  payload: CustomerRoute;
}

export interface UpdateReturnedRouteAction {
  type: typeof UPDATE_RETURNED_ROUTE;
  payload: CustomerRoute;
}

export type RouteActionTypes =
  | UpdateRouteAction
  | UpdateReturnedRouteAction
  | IncreaseOffRouteCountAction
  | ResetOffRouteCountAction;
