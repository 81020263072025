import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React from "react";
import { Message } from "../../models/message.model";
import { MessagePropsFromRedux } from "./message.container";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const MessageComponent: React.FC<MessagePropsFromRedux> = (
  props: MessagePropsFromRedux
) => {
  const onClose = (message: Message) => {
    props.removeMessage(message);
  };

  if (props.messages.length === 0) return <></>;

  return (
    <>
      {props.messages.map((value, key) => {
        return (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            key={key}
            open={true}
          >
            <Alert onClose={() => onClose(value)} severity={value.severity}>
              {value.message}
            </Alert>
          </Snackbar>
        );
      })}
    </>
  );
};
