import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers/root.reducer";
import { PrivacyZoneComponent } from "./privacy-zone.component";

const mapState = (state: RootState) => ({
  inPrivacyZoneId:
    state.orderLocationReducer.orderLocation?.currentLocation?.inPrivacyZoneId,
  activeRoute: state.routeReducer.route?.activeRoute,
  subsequentRoute: state.routeReducer.route?.subsequentRoute,
  snappedLocation: state.orderLocationReducer.snappedOrderLocation,
});

const connector = connect(mapState);

export type PrivacyZonePropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PrivacyZoneComponent);
