import { distance } from "@turf/turf";
import { FeatureCollection } from "geojson";
import mapboxgl, { GeoJSONSourceRaw, LngLat } from "mapbox-gl";
import { OrderLocationPoint, OrderStatus } from "../graphql/types";
import { getConfig } from "./config/config.service";

let lastBoundTime = 0;

export function getSource(
  id: string,
  map: mapboxgl.Map
): mapboxgl.GeoJSONSource {
  return map.getSource(id) as mapboxgl.GeoJSONSource;
}

export const resetLastBoundTime: () => void = () => {
  lastBoundTime = 0;
};

export const createOrUpdateExistingLayer: (
  map: mapboxgl.Map,
  layer: mapboxgl.AnyLayer
) => void = (map: mapboxgl.Map, layer: mapboxgl.AnyLayer) => {
  const data = ((layer as mapboxgl.Layer)?.source as GeoJSONSourceRaw)
    ?.data as FeatureCollection;
  if (data) {
    const source = getSource(layer.id, map);
    if (source) {
      source.setData(data);
    } else {
      map.addLayer(layer);
    }
  }
};

const isDistanceOverThreshold = (mapCenter: LngLat, boundsCenter: LngLat) => {
  const distanceBetweenCenters = distance(
    getCoordFromLngLat(mapCenter),
    getCoordFromLngLat(boundsCenter),
    {
      units: "meters",
    }
  );
  return distanceBetweenCenters >= getConfig().mapBoundDistanceThresholdMeters;
};

const hasEnoughTimePassed = () => {
  const currentTime = performance.now();
  const timeSinceLastBound = currentTime - lastBoundTime;
  if (timeSinceLastBound >= getConfig().mapBoundTimeThresholdSeconds * 1000) {
    lastBoundTime = currentTime;
    return true;
  }
  return false;
};

export const boundMaptoCoordinates: (
  coordinates: [number, number][],
  map: mapboxgl.Map,
  fastPan?: boolean
) => void = (
  coordinates: [number, number][],
  map: mapboxgl.Map,
  fastPan?: boolean
) => {
  if (coordinates.length >= 1) {
    coordinates = coordinates.filter((x) => {
      if (
        x[0] === 0 ||
        x[1] === 0 ||
        Math.abs(x[0]) > 90 ||
        Math.abs(x[1]) > 90
      ) {
        return false;
      }
      return true;
    });
    const bounds = coordinates.reduce((bounds, coord) => {
      return bounds.extend(coord as mapboxgl.LngLatLike);
    }, new mapboxgl.LngLatBounds(coordinates[0] as mapboxgl.LngLatLike, coordinates[0] as mapboxgl.LngLatLike));
    const mapCenter = map.getCenter();
    const boundsCenter = bounds.isEmpty() ? null : bounds.getCenter();
    if (
      mapCenter &&
      boundsCenter &&
      isDistanceOverThreshold(mapCenter, boundsCenter) &&
      (hasEnoughTimePassed() || fastPan)
    ) {
      let config = {};
      if (fastPan) {
        config = {
          padding: 60,
          speed: 1,
          animate: true,
        };
      } else {
        config = {
          padding: 60,
          speed: 0.1,
          animate: true,
          duration: getConfig().mapBoundTimeThresholdSeconds * 1000,
        };
      }
      map.fitBounds(bounds, config);
    }
  }
};

export const getCoordFromOrderLocationPoint: (
  orderLocation: OrderLocationPoint
) => [number, number] = (orderLocation: OrderLocationPoint) => {
  return [orderLocation.lng, orderLocation.lat];
};

export const getCoordFromLngLat: (location: LngLat) => [number, number] = (
  location: LngLat
) => {
  return [location.lng, location.lat];
};

export const isOrderBeingDelivered: (status?: OrderStatus) => boolean = (
  status?: OrderStatus
) => {
  return status === OrderStatus.Started;
};
