import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers/root.reducer";
import { OrderStatusMessagesComponent } from "./order-status-messages.component";
import {
  addMessage,
  removeMessage,
} from "../../redux/reducers/messages/messages.actions";

const mapState = (state: RootState) => ({
  orderStatus: state.orderDetailsReducer.orderDetails?.status,
  orderNotDeliveredReason:
    state.orderDetailsReducer.orderDetails?.cancellationReason,
  orderGeofenceEvents: state.orderDetailsReducer.orderDetails?.geofencingEvents,
});

const mapDispatch = {
  addMessage,
  removeMessage,
};

const connector = connect(mapState, mapDispatch);

export type OrderStatusMessagesPropsFromRedux = ConnectedProps<
  typeof connector
>;

export default connector(OrderStatusMessagesComponent);
