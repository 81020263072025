import { getConfig } from "../config/config.service";
import { Bugfender } from "@bugfender/sdk";

export let remoteLoggingEnabled = false;

export const setupLogging: () => void = () => {
  const config = getConfig();
  if (
    process.env.NODE_ENV &&
    process.env.NODE_ENV === "production" &&
    config.bugFenderAppKey
  ) {
    Bugfender.init({
      appKey: config.bugFenderAppKey,
    }).then(() => {
      remoteLoggingEnabled = true;
    });
  }
};

export const setLoggingCustomerCode: (customerCode: string) => void = (
  customerCode: string
) => {
  if (remoteLoggingEnabled) {
    Bugfender.setDeviceKey("customer_code", customerCode);
  }
};
