import React, { useEffect, useRef, useState } from "react";
import { CarPropsFromRedux } from "./car.container";
import { Marker, Map } from "mapbox-gl";
import {
  animateAndRotateCar,
  resetLastAnimation,
  rotateCar,
  setCarAnnimationOff,
} from "./car.service";
import CarImage from "../../images/car.svg";
import { updateMarkerPoint } from "../../services/marker/marker.service";
import { OrderLocationPoint } from "../../graphql/types";
import {
  getCoordFromOrderLocationPoint,
  getCoordFromLngLat,
} from "../../services/utils.service";
import {
  emptyActiveLegWith2PointsAfterEnteringPZ,
  removeTraversedRoute,
} from "../route/route.service";
import { getSnappedPoint } from "../../services/snapped-point/snapped-point.service";
import { Position } from "@turf/turf";
export interface CarProps extends CarPropsFromRedux {
  map: Map;
}

type CarState = "Created" | "Adding" | "InPrivacyZone" | "OnMap";

const CarComponent: React.FC<CarProps> = (props: CarProps) => {
  const [car, setCar] = useState<Marker>();
  const [carState, setCarState] = useState<CarState>("Created");
  const carContainerRef = useRef<HTMLDivElement>(null);

  const createCar: (carElement: HTMLElement) => void = (
    carElement: HTMLElement
  ) => {
    carElement.className = "marker";
    carElement.style.backgroundImage = `url(${CarImage})`;
    carElement.style.width = "50px";
    carElement.style.height = "50px";
    carElement.style.position = "absolute";
    carElement.style.backgroundSize = "cover";
    carElement.style.backgroundRepeat = "no-repeat";

    const newCar = new Marker(carElement);
    setCar(newCar);
  };

  const trimFromSnappedLocation = (
    orderLocation: OrderLocationPoint | undefined | null
  ) => {
    if (props.activeRoute?.features[0] && orderLocation) {
      const position = getCoordFromOrderLocationPoint(
        orderLocation
      ) as Position;
      const snappedPosition = getSnappedPoint(position, [
        props.activeRoute.features[0],
      ]);
      if (snappedPosition) {
        emptyActiveLegWith2PointsAfterEnteringPZ(props.activeRoute);
        removeTraversedRoute(props.activeRoute, snappedPosition, props.map);
      }
    }
  };

  const carInPrivacyZoneRemoveAndTrim: (
    car: Marker,
    orderLocation: OrderLocationPoint
  ) => void = (car: Marker, orderLocation: OrderLocationPoint) => {
    if (carState !== "InPrivacyZone") {
      setCarAnnimationOff();
      // trims at the last known point
      trimFromSnappedLocation(props.orderLocation?.previousLocation);
      // trims from the center to remove and leftover segments
      trimFromSnappedLocation(orderLocation);
      car.remove();
      setCarState("InPrivacyZone");
      car.setLngLat(getCoordFromOrderLocationPoint(orderLocation));
      setCarAnnimationOff(false);
    }
  };

  const updateCarPosition: (newPoint: [number, number], car: Marker) => void = (
    newPoint: [number, number],
    car: Marker
  ) => {
    if (
      carState === "InPrivacyZone" ||
      (carState === "Created" && !props.orderLocation?.previousLocation)
    ) {
      // coming out of PZ only one point on route is available so position car but don't show or animate
      // edge case for first point being shipped without a previous location - should never happen
      setCarAdding(car, newPoint);
    } else {
      if (carState === "Created" && props.orderLocation?.previousLocation) {
        //set the current marker to be the previous location if in state created
        updateMarkerPoint(
          getCoordFromOrderLocationPoint(props.orderLocation?.previousLocation),
          car
        );
      }
      //get the current point of the car
      const oldPoint = getCoordFromLngLat(car.getLngLat());

      //add car to map if it is not already
      if (carState !== "OnMap") {
        setCarOnMap(car, oldPoint, newPoint);
        resetLastAnimation();
      }
      animateAndRotateCarWithProps(oldPoint, newPoint, car);
    }
  };

  const setCarAdding = (car: Marker, newPoint: [number, number]) => {
    updateMarkerPoint(newPoint, car);
    setCarState("Adding");
  };

  const setCarOnMap = (
    car: Marker,
    oldPoint: [number, number],
    newPoint: [number, number]
  ) => {
    setCarAnnimationOff();
    rotateCar(oldPoint, newPoint, car);
    setCarAnnimationOff(false);
    car.addTo(props.map);
    setCarState("OnMap");
  };

  const animateAndRotateCarWithProps = (
    oldPoint: [number, number],
    newPoint: [number, number],
    car: Marker
  ) => {
    animateAndRotateCar(
      oldPoint,
      newPoint,
      car,
      props.map,
      props.activeRoute,
      props.orderLocation?.previousLocation
        ? getCoordFromOrderLocationPoint(props.orderLocation?.previousLocation)
        : void 0
    );
  };

  const isNewPointInPrivacyZone = (orderLocation: OrderLocationPoint) => {
    return orderLocation.inPrivacyZoneId;
  };

  useEffect(() => {
    if (carContainerRef?.current !== void 0) {
      createCar(carContainerRef.current as HTMLElement);
    }
  }, []);

  useEffect(() => {
    if (props.snappedOrderLocation && car) {
      if (isNewPointInPrivacyZone(props.snappedOrderLocation)) {
        carInPrivacyZoneRemoveAndTrim(car, props.snappedOrderLocation);
      } else {
        updateCarPosition(
          getCoordFromOrderLocationPoint(props.snappedOrderLocation),
          car
        );
      }
    }
  }, [props.snappedOrderLocation, car]);

  return <div ref={carContainerRef} />;
};

export default CarComponent;
