import React from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";

import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { App } from "./App";
import Amplify, { Auth } from "aws-amplify";

import * as config from "./config.json";
import { getAppSyncClient } from "./graphql/services/graphql-client/graphql-client.service";
import {
  remoteLoggingEnabled,
  setupLogging,
} from "./services/logging/logging.service";

Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.awsRegion,
  },
});

Auth.configure();

// init client to avoid racecondition for actual calls
getAppSyncClient();
setupLogging();

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
remoteLoggingEnabled ?? reportWebVitals(console.log);
