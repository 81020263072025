/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDriverDevice = /* GraphQL */ `
  query GetDriverDevice($deviceId: String) {
    getDriverDevice(deviceId: $deviceId) {
      id
      vendorRegistrations {
        id
        vendorId
        vendorName
        qrCodePayload
        registrationDate
      }
      routes {
        id
        deviceId
        orders {
          status
          cancellationReason
          customerAccessCode
          routePosition
          privacyZones {
            id
            center {
              lat
              lng
            }
            radiusMetres
            transparent
            completed
          }
          directions {
            hashcode
            legs {
              activeLeg
            }
          }
          addressOrganisation
          addressFirstLine
          addressSecondLine
          addressTown
          addressLocality
          addressPostcode
          customerLocation {
            lat
            lng
          }
          scanType
          vendorId
          vendorLocation {
            lat
            lng
          }
          createdDate
          startedDate
          finishedDate
          geofencingEvents {
            eventType
          }
          version
        }
        status
        routingType
        startLocation {
          lat
          lng
        }
        estimatedRoundTripMinutes
        estimatedDeliveryDurationMinutes
        estimatedSecondsPerStop
        dateCreated
        dateFinalized
        dateCompleted
        durationMatrix {
          destinations {
            lat
            lng
          }
          sources {
            lat
            lng
          }
          durations
        }
        version
      }
      dateCreated
      operatingSystem
      version
    }
  }
`;
export const getDriverRoute = /* GraphQL */ `
  query GetDriverRoute($deviceId: String, $routeId: String) {
    getDriverRoute(deviceId: $deviceId, routeId: $routeId) {
      id
      deviceId
      orders {
        status
        cancellationReason
        customerAccessCode
        routePosition
        privacyZones {
          id
          center {
            lat
            lng
          }
          radiusMetres
          transparent
          completed
        }
        directions {
          hashcode
          legs {
            steps {
              encodedPolyline
            }
            activeLeg
          }
        }
        addressOrganisation
        addressFirstLine
        addressSecondLine
        addressTown
        addressLocality
        addressPostcode
        customerLocation {
          lat
          lng
        }
        scanType
        vendorId
        vendorLocation {
          lat
          lng
        }
        createdDate
        startedDate
        finishedDate
        geofencingEvents {
          eventType
        }
        version
      }
      status
      routingType
      startLocation {
        lat
        lng
      }
      estimatedRoundTripMinutes
      estimatedDeliveryDurationMinutes
      estimatedSecondsPerStop
      dateCreated
      dateFinalized
      dateCompleted
      durationMatrix {
        destinations {
          lat
          lng
        }
        sources {
          lat
          lng
        }
        durations
      }
      version
    }
  }
`;
export const getDriverOrder = /* GraphQL */ `
  query GetDriverOrder($orderId: String) {
    getDriverOrder(orderId: $orderId) {
      status
      cancellationReason
      customerAccessCode
      routePosition
      privacyZones {
        id
        center {
          lat
          lng
        }
        radiusMetres
        transparent
        completed
      }
      directions {
        hashcode
        legs {
          steps {
            encodedPolyline
          }
          activeLeg
        }
      }
      addressOrganisation
      addressFirstLine
      addressSecondLine
      addressTown
      addressLocality
      addressPostcode
      customerLocation {
        lat
        lng
      }
      scanType
      vendorId
      vendorLocation {
        lat
        lng
      }
      createdDate
      startedDate
      finishedDate
      geofencingEvents {
        eventType
      }
      version
    }
  }
`;
export const getCustomerOrder = /* GraphQL */ `
  query GetCustomerOrder($customerAccessCode: String) {
    getCustomerOrder(customerAccessCode: $customerAccessCode) {
      status
      cancellationReason
      customerAccessCode
      routePosition
      privacyZones {
        id
        center {
          lat
          lng
        }
        radiusMetres
        transparent
        completed
      }
      directions {
        hashcode
        legs {
          steps {
            encodedPolyline
          }
          activeLeg
        }
      }
      addressOrganisation
      addressFirstLine
      addressSecondLine
      addressTown
      addressLocality
      addressPostcode
      customerLocation {
        lat
        lng
      }
      scanType
      vendorId
      vendorLocation {
        lat
        lng
      }
      createdDate
      startedDate
      finishedDate
      geofencingEvents {
        eventType
      }
      version
    }
  }
`;
export const getOrderLocation = /* GraphQL */ `
  query GetOrderLocation($customerAccessCode: String) {
    getOrderLocation(customerAccessCode: $customerAccessCode) {
      customerAccessCode
      currentLocation {
        lat
        lng
        timestamp
        inPrivacyZoneId
      }
      previousLocation {
        lat
        lng
        timestamp
        inPrivacyZoneId
      }
      eta
    }
  }
`;
