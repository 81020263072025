import {
  LineString,
  Position,
  pointToLineDistance,
  distance,
  rhumbBearing,
} from "@turf/turf";
import { createOrUpdateExistingLayer } from "../../services/utils.service";
import { getChangeOfBearing } from "../car/car.service";

export const updateActiveRouteOnMap: (
  activeRoute: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  >,
  map: mapboxgl.Map
) => void = (
  activeRoute: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  >,
  map: mapboxgl.Map
) => {
  createOrUpdateExistingLayer(map, {
    id: "activeroute",
    type: "line",
    source: {
      type: "geojson",
      data: activeRoute,
    },
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#0000FF",
      "line-width": 5,
      "line-opacity": 0.3,
    },
  });
};

export const filterEmptyLegs: (
  legs: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[]
) => GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] = (
  legs: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[]
) => {
  return legs.filter((leg) => {
    return (leg.geometry as LineString).coordinates.length > 1;
  });
};

export const removeTraversedLegs: (
  legs: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[],
  currentPoint: Position
) => GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] = (
  legs: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[],
  currentPoint: Position
) => {
  let legIndex = 0;
  let nearestLegDistance = 99999;
  legs.forEach((feature, index) => {
    if ((feature.geometry as LineString).coordinates.length > 1) {
      const distance = pointToLineDistance(currentPoint, feature, {
        units: "meters",
      });
      if (distance < nearestLegDistance) {
        nearestLegDistance = distance;
        legIndex = index;
      }
    }
  });

  if (legIndex > 0) {
    legs.splice(0, legIndex);
  }
  legs = filterEmptyLegs(legs);
  return legs;
};

export const emptyActiveLegWith2PointsAfterEnteringPZ: (
  activeRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined
) => void = (
  activeRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined
) => {
  if (activeRoute && activeRoute.features.length > 0) {
    const coordinates = (activeRoute.features[0].geometry as LineString)
      .coordinates;
    if (coordinates.length <= 2) {
      (activeRoute.features[0].geometry as LineString).coordinates = [];
    }
  }
};

export const removeTraversedRoute: (
  activeRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined,
  currentPoint: Position,
  map?: mapboxgl.Map,
  carHeading?: number,
  previousPoint?: Position
) => void = (
  activeRoute:
    | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    | undefined,
  currentPoint: Position,
  map?: mapboxgl.Map,
  carHeading?: number, // nullable for PZ's
  previousPoint?: Position
) => {
  if (activeRoute && currentPoint) {
    activeRoute.features = removeTraversedLegs(
      activeRoute.features,
      currentPoint
    );
    if (activeRoute.features.length > 0) {
      let activeRouteCoordinates = (activeRoute.features[0]
        .geometry as LineString).coordinates;
      const segmentIndex = getCurrentPointSegment(
        Object.assign([], activeRouteCoordinates),
        currentPoint,
        carHeading,
        previousPoint
      );
      activeRouteCoordinates = clearCompletedSegments(
        Object.assign([], activeRouteCoordinates),
        segmentIndex
      );
      if (activeRouteCoordinates.length > 0) {
        activeRouteCoordinates = updateRouteFirstPointIfSegmentIsShorterThanCurrentSegment(
          activeRouteCoordinates,
          currentPoint
        );
      }
      (activeRoute.features[0]
        .geometry as LineString).coordinates = activeRouteCoordinates;
    }

    if (map) {
      updateActiveRouteOnMap(activeRoute, map);
    }
  }
};

const getCurrentPointSegment = (
  coordinates: Position[],
  currentPoint: Position,
  carHeading?: number,
  previousPoint?: Position
) => {
  let segmentIndex = 0;

  if (previousPoint) {
    // replace point where the car last was (animation) with the last update
    coordinates.splice(0, 1, previousPoint);
  }
  let closestDistance = pointToLineBetweenPointsDistance(
    currentPoint,
    coordinates[0],
    coordinates[0 + 1]
  );

  for (let i = 1; i < coordinates.length - 1; i++) {
    const coordinateDistance = pointToLineBetweenPointsDistance(
      currentPoint,
      coordinates[i],
      coordinates[i + 1]
    );
    if (
      coordinateDistance < closestDistance &&
      (carHeading === void 0 ||
        isSegmentBearingToCarBearingWithinThreshold(
          coordinates[i],
          coordinates[i + 1],
          carHeading
        ))
    ) {
      closestDistance = coordinateDistance;
      segmentIndex = i;
    }
  }
  return segmentIndex;
};

export const isSegmentBearingToCarBearingWithinThreshold: (
  segmentA: Position,
  segmentB: Position,
  carHeading: number
) => boolean = (segmentA: Position, segmentB: Position, carHeading: number) => {
  const segmentHeading = rhumbBearing(segmentA, segmentB);
  const difference = Math.abs(getChangeOfBearing(carHeading, segmentHeading));
  return difference < 90;
};

const clearCompletedSegments = (
  activeRouteCoordinates: Position[],
  segmentIndex: number
) => {
  if (segmentIndex !== 0) {
    activeRouteCoordinates.splice(0, segmentIndex);
  }
  return activeRouteCoordinates;
};

export const updateRouteFirstPointIfSegmentIsShorterThanCurrentSegment: (
  activeRouteCoordinates: Position[],
  currentPoint: Position
) => Position[] = (
  activeRouteCoordinates: Position[],
  currentPoint: Position
) => {
  const proposedSegment = distance(currentPoint, activeRouteCoordinates[1], {
    units: "meters",
  });
  const oldSegment = distance(
    activeRouteCoordinates[0],
    activeRouteCoordinates[1],
    { units: "meters" }
  );
  if (proposedSegment < oldSegment) {
    activeRouteCoordinates[0] = [...currentPoint];
  }
  return activeRouteCoordinates;
};

const pointToLineBetweenPointsDistance = (
  point: Position,
  linePointA: Position,
  linePointB: Position
) => {
  const feature = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [linePointA, linePointB],
    },
  } as GeoJSON.Feature;
  return pointToLineDistance(point, feature, {
    units: "meters",
  });
};
