import {
  DisableBoundAction,
  DISABLE_BOUND_MAP_ON_LOCATION,
  EnableBoundAction,
  ENABLE_BOUND_MAP_ON_LOCATION,
  MapActionTypes,
} from "./map.action-types";

export const enableBoundMapOnLocation: () => MapActionTypes = () => {
  return {
    type: ENABLE_BOUND_MAP_ON_LOCATION,
  } as EnableBoundAction;
};

export const disableBoundMapOnLocation: () => MapActionTypes = () => {
  return {
    type: DISABLE_BOUND_MAP_ON_LOCATION,
  } as DisableBoundAction;
};
