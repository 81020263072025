import { Position, circle } from "@turf/turf";

export const getNESWPointFromCoordAndDistance: (
  coord: Position,
  distanceMeters: number
) => Position[] = (coord: Position, distanceMeters: number) => {
  if (distanceMeters) {
    const circ = circle(coord, distanceMeters, { steps: 4, units: "meters" });
    return circ.geometry?.coordinates[0] as Position[];
  }
  return [];
};
