/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToOrderLocation = /* GraphQL */ `
  subscription SubscribeToOrderLocation($customerAccessCode: String!) {
    subscribeToOrderLocation(customerAccessCode: $customerAccessCode) {
      customerAccessCode
      currentLocation {
        lat
        lng
        timestamp
        inPrivacyZoneId
      }
      previousLocation {
        lat
        lng
        timestamp
        inPrivacyZoneId
      }
      eta
    }
  }
`;
export const subscribeDriverOrder = /* GraphQL */ `
  subscription SubscribeDriverOrder($customerAccessCode: String) {
    subscribeDriverOrder(customerAccessCode: $customerAccessCode) {
      status
      cancellationReason
      customerAccessCode
      routePosition
      privacyZones {
        id
        center {
          lat
          lng
        }
        radiusMetres
        transparent
        completed
      }
      directions {
        hashcode
        legs {
          steps {
            encodedPolyline
          }
          activeLeg
        }
      }
      addressOrganisation
      addressFirstLine
      addressSecondLine
      addressTown
      addressLocality
      addressPostcode
      customerLocation {
        lat
        lng
      }
      scanType
      vendorId
      vendorLocation {
        lat
        lng
      }
      createdDate
      startedDate
      finishedDate
      geofencingEvents {
        eventType
      }
      version
    }
  }
`;
export const subscribeToGeofencingUpdates = /* GraphQL */ `
  subscription SubscribeToGeofencingUpdates($customerAccessCode: String) {
    subscribeToGeofencingUpdates(customerAccessCode: $customerAccessCode) {
      customerAccessCode
      geofencingEvents {
        eventType
      }
    }
  }
`;
