import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers/root.reducer";
import { MessageComponent } from "./message.component";
import { removeMessage } from "../../redux/reducers/messages/messages.actions";

const mapState = (state: RootState) => ({
  messages: state.messagesReducer.messages,
});

const mapDispatch = {
  removeMessage,
};

const connector = connect(mapState, mapDispatch);

export type MessagePropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MessageComponent);
