import { MarkerModel } from "../../models/marker.model";
import destinationMarkerImage from "../../images/home_icon.svg";
import vendorMarkerImage from "../../images/vendor_icon.svg";
import { Position } from "@turf/turf";

export const createVendorMarker: (
  vendorpoint: [number, number]
) => MarkerModel = (vendorpoint: [number, number]) => {
  return {
    markerImage: vendorMarkerImage,
    width: "40px",
    height: "40px",
    position: vendorpoint as Position,
  } as MarkerModel;
};

export const getDestinationMarker: (
  endpoint: [number, number]
) => MarkerModel = (endpoint: [number, number]) => {
  return {
    markerImage: destinationMarkerImage,
    width: "40px",
    height: "40px",
    position: endpoint as Position,
  } as MarkerModel;
};

export const updateMarkerPoint: (
  point: [number, number],
  marker: mapboxgl.Marker
) => void = (point: [number, number], marker: mapboxgl.Marker) => {
  marker.setLngLat(point);
};
