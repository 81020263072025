import { combineReducers } from "redux";
import { customerCodeReducer } from "./customer-code/customer-code.reducer";
import { orderDetailsReducer } from "./order-details/order-details.reducer";
import { routeReducer } from "./route/route.reducer";
import { orderLocationReducer } from "./order-location/order-location.reducer";
import { messagesReducer } from "./messages/messages.reducer";
import { mapReducer } from "./map/map.reducer";

export const rootReducer = combineReducers({
  customerCodeReducer,
  orderDetailsReducer,
  routeReducer,
  orderLocationReducer,
  messagesReducer,
  mapReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
