import {
  getHiddenPropertyName,
  getVisibilityChangePropertyName,
} from "./browserProperties.service";

const getHiddenValue = () => {
  const hiddenProperty = getHiddenPropertyName();
  if (hiddenProperty) {
    return document[hiddenProperty as keyof Document];
  }
};

const handleVisibilityChange = (callback: () => void) => {
  const hidden = getHiddenValue();
  if (!hidden) {
    callback();
  }
};

export const setupReconnectListener: (callback: () => void) => void = (
  callback: () => void
) => {
  const visibilityChange = getVisibilityChangePropertyName();

  if (typeof document.addEventListener !== "undefined" && visibilityChange) {
    document.addEventListener(
      visibilityChange,
      () => handleVisibilityChange(callback),
      false
    );
  }
};

export const removeReconnectListener: (callback: () => void) => void = (
  callback: () => void
) => {
  const visibilityChange = getVisibilityChangePropertyName();

  if (typeof document.removeEventListener !== "undefined" && visibilityChange) {
    document.removeEventListener(
      visibilityChange,
      /* istanbul ignore next */
      () => handleVisibilityChange(callback),
      false
    );
  }
};
