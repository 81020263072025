export const ENABLE_BOUND_MAP_ON_LOCATION = "ENABLE_BOUND_MAP_ON_LOCATION";
export const DISABLE_BOUND_MAP_ON_LOCATION = "DISABLE_BOUND_MAP_ON_LOCATION";

export interface EnableBoundAction {
  type: typeof ENABLE_BOUND_MAP_ON_LOCATION;
}

export interface DisableBoundAction {
  type: typeof DISABLE_BOUND_MAP_ON_LOCATION;
}

export type MapActionTypes = EnableBoundAction | DisableBoundAction;
