import { getConfig } from "../../../services/config/config.service";
import { AWSAppSyncClient, AUTH_TYPE } from "aws-appsync";
import { NormalizedCacheObject } from "aws-appsync/node_modules/apollo-cache-inmemory";
import { Auth } from "aws-amplify";

let graphQLClient: AWSAppSyncClient<NormalizedCacheObject> | null = null;

export const getAppSyncClient: () => AWSAppSyncClient<NormalizedCacheObject> = () => {
  if (graphQLClient) {
    return graphQLClient;
  }

  graphQLClient = createClient();
  return graphQLClient;
};

export const resetAppSyncClient: () => void = () => {
  graphQLClient = null;
};

const createClient: () => AWSAppSyncClient<NormalizedCacheObject> = () => {
  return new AWSAppSyncClient<NormalizedCacheObject>({
    url: getConfig().apiUrl,
    region: getConfig().awsRegion,
    auth: {
      type: AUTH_TYPE.AWS_IAM,
      credentials: async () => await Auth.currentCredentials(),
    },
    disableOffline: true,
  });
};
