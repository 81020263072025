import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers/root.reducer";

import { EtaComponent } from "./eta.component";

const mapState = (state: RootState) => ({
  eta: state.orderLocationReducer.orderLocation?.eta,
  orderStatus: state.orderDetailsReducer.orderDetails?.status,
});

const connector = connect(mapState);

export type EtaPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EtaComponent);
