import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers/root.reducer";

import { RouteComponent } from "./route.component";

const mapState = (state: RootState) => ({
  route: state.routeReducer.route,
  boundMapOnLocationUpdate: state.mapReducer.boundMapOnLocationUpdate,
  snappedLocationPoint: state.orderLocationReducer.snappedOrderLocation,
  customerLocation: state.orderDetailsReducer.orderDetails?.customerLocation,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

export type RoutePropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RouteComponent);
