import Observable from "zen-observable-ts";

import * as query from "../../../graphql/queries";
import * as subscriptions from "../../../graphql/subscriptions";
import { DriverOrder, Query, Subscription } from "../../types";

import { driverOrder } from "./test_order_data";
import gql from "graphql-tag";
import { getAppSyncClient } from "../graphql-client/graphql-client.service";
import { getConfig } from "../../../services/config/config.service";
import { GraphQLResult } from "../../../models/graphql-result.model";

export const getOrderDetails: (
  customerCode: string
) => Promise<GraphQLResult<Query>> = (customerCode: string) => {
  // mocked
  if (getConfig().mockApi) {
    const result = {
      data: { getCustomerOrder: driverOrder as DriverOrder },
    } as GraphQLResult<Query>;
    return Promise.resolve(result);
  }

  //amplify
  const client = getAppSyncClient();
  return client.query<Query>({
    query: gql(query.getCustomerOrder),
    variables: {
      customerAccessCode: customerCode,
    },
    fetchPolicy: "no-cache",
  });
};

export const orderSubscription: (
  customerAccessCode: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Observable<GraphQLResult<Subscription>> = (customerAccessCode: string) => {
  // mocked
  if (getConfig().mockApi) {
    const observable: Observable<GraphQLResult<Subscription>> = new Observable<
      GraphQLResult<Subscription>
    >((observer) => {
      setTimeout(
        () =>
          observer.next({
            data: {
              subscribeDriverOrder: Object.assign(driverOrder) as DriverOrder,
            },
          } as GraphQLResult<Subscription>),
        5000
      );
    });
    return observable;
  }

  // amplify

  const client = getAppSyncClient();

  return client.subscribe<GraphQLResult<Subscription>>({
    query: gql(subscriptions.subscribeDriverOrder),
    variables: {
      customerAccessCode: customerAccessCode,
    },
  });
};

export const geofenceSubscription: (
  customerAccessCode: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Observable<GraphQLResult<Subscription>> = (customerAccessCode: string) => {
  // amplify

  const client = getAppSyncClient();

  return client.subscribe<GraphQLResult<Subscription>>({
    query: gql(subscriptions.subscribeToGeofencingUpdates),
    variables: {
      customerAccessCode: customerAccessCode,
    },
  });
};
