import Observable from "zen-observable-ts";
import * as subscriptions from "../../subscriptions";
import gql from "graphql-tag";
import { getAppSyncClient } from "../graphql-client/graphql-client.service";
import { getConfig } from "../../../services/config/config.service";
import { driverOrder } from "../order-details/test_order_data";
import polyline from "@mapbox/polyline";
import { GraphQLResult } from "../../../models/graphql-result.model";
import {
  OrderLocation,
  OrderLocationPoint,
  Query,
  Subscription,
} from "../../types";
import * as queries from "../../queries";

export const orderLocationSubscription: (
  customerCode: string
) => Observable<GraphQLResult<Subscription>> = (customerCode: string) => {
  // mocked
  if (getConfig().mockApi) {
    const observable: Observable<GraphQLResult<Subscription>> = new Observable<
      GraphQLResult<Subscription>
    >((observer) => {
      // This is mocking a graph ql subscription
      let positionList = new Array<[number, number]>();
      driverOrder.directions?.legs.forEach((leg) => {
        leg.steps.forEach((step) => {
          const list = polyline.decode(step.encodedPolyline, 6);
          list.forEach((x) => x.reverse());
          positionList = positionList.concat(list);
        });
      });
      const dummyRoute = positionList.map((x) => {
        return {
          lat: x[1],
          lng: x[0],
          orderId: "test",
          id: "1",
          inPrivacyZoneId: null,
          customerAccessCode: customerCode,
          timestamp: new Date(Date.now()).toISOString(),
        } as OrderLocationPoint;
      });
      let locationCounter = 0;
      let timer: NodeJS.Timeout;
      const updateLocation = async () => {
        if (locationCounter < dummyRoute.length) {
          timer = setTimeout(() => {
            observer.next({
              data: {
                subscribeToOrderLocation: {
                  currentLocation: {
                    ...dummyRoute[locationCounter],
                  } as OrderLocationPoint,
                },
              },
            } as GraphQLResult<Subscription>);
            locationCounter++;
            updateLocation();
          }, 1000);
        }
      };
      updateLocation();
      const unsubscribeAction = () => clearTimeout(timer);
      return unsubscribeAction;
    });
    return observable;
  }

  // amplify

  const client = getAppSyncClient();

  return client.subscribe<GraphQLResult<Subscription>>({
    query: gql(subscriptions.subscribeToOrderLocation),
    variables: {
      customerAccessCode: customerCode,
    },
  });
};

export const getOrderLocation: (
  customerCode: string
) => Promise<GraphQLResult<Query>> = (customerCode: string) => {
  // mocked
  if (getConfig().mockApi) {
    return Promise.resolve({
      data: {
        getOrderLocation: {
          currentLocation: {
            lat: 55.896784,
            lng: -3.609508,
            timestamp: "00:00:00",
            orderId: "test",
            id: "1",
            inPrivacyZoneId: null,
            customerAccessCode: "test",
          } as OrderLocationPoint,
          previousLocation: {
            lat: 55.896932,
            lng: -3.608744,
            timestamp: "00:00:00",
            orderId: "test",
            id: "1",
            inPrivacyZoneId: null,
            customerAccessCode: "test",
          } as OrderLocationPoint,
        } as OrderLocation,
      } as Query,
    } as GraphQLResult<Query>);
  }
  // amplify
  const client = getAppSyncClient();

  return client.query<Query>({
    query: gql(queries.getOrderLocation),
    variables: {
      customerAccessCode: customerCode,
    },
    fetchPolicy: "no-cache",
  });
};
