import {
  IncreaseOffRouteCountAction,
  INCREASE_OFF_ROUTE_COUNT,
  ResetOffRouteCountAction,
  RESET_OFF_ROUTE_COUNT,
  UpdateReturnedRouteAction,
  UpdateRouteAction,
  UPDATE_RETURNED_ROUTE,
  UPDATE_ROUTE,
} from "./route.action-types";

import { CustomerRoute } from "../../../models/route.model";
import { DriverOrder, OrderLocationPoint } from "../../../graphql/types";
import polyline from "@mapbox/polyline";
import { getConfig } from "../../../services/config/config.service";
import { removeTraversedRoute } from "../../../components/route/route.service";
import { getCoordFromOrderLocationPoint } from "../../../services/utils.service";

export const updateRoute: (route: CustomerRoute) => UpdateRouteAction = (
  route: CustomerRoute
) => {
  return {
    type: UPDATE_ROUTE,
    payload: route,
  } as UpdateRouteAction;
};
export const updateReturnedRoute: (
  route: CustomerRoute
) => UpdateReturnedRouteAction = (route: CustomerRoute) => {
  return {
    type: UPDATE_RETURNED_ROUTE,
    payload: route,
  } as UpdateReturnedRouteAction;
};

export const resetOffRouteCount: () => ResetOffRouteCountAction = () =>
  ({ type: RESET_OFF_ROUTE_COUNT } as ResetOffRouteCountAction);

export const increaseOffRouteCount: (
  currentLocation: OrderLocationPoint
) => IncreaseOffRouteCountAction = (currentLocation: OrderLocationPoint) =>
  ({
    type: INCREASE_OFF_ROUTE_COUNT,
    payload: currentLocation,
  } as IncreaseOffRouteCountAction);

export const convertOrderToRoute: (
  order: DriverOrder,
  currentLocation: OrderLocationPoint | null
) => CustomerRoute = (
  order: DriverOrder,
  currentLocation: OrderLocationPoint | null
) => {
  const subsequentRoute: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > = {
    type: "FeatureCollection",
    features: [],
  };

  const activeRoute: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > = {
    type: "FeatureCollection",
    features: [],
  } as GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;

  if (order?.directions?.legs) {
    for (const legIndex in order.directions.legs) {
      const leg = order.directions.legs[legIndex];
      let legPath = [] as [number, number][];

      for (const stepIndex in leg.steps) {
        const step = leg.steps[stepIndex];
        const encodedPath = step.encodedPolyline;
        const decodedPath = polyline.decode(
          encodedPath,
          getConfig().polylinePrecision
        );
        decodedPath.forEach((x) => x.reverse());
        legPath = legPath.concat(decodedPath);
      }
      const feature = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: legPath,
        },
      } as GeoJSON.Feature;
      if (leg.activeLeg) {
        activeRoute.features.push(feature);
      } else {
        subsequentRoute.features.push(feature);
      }
    }
  }
  if (currentLocation) {
    removeTraversedRoute(
      activeRoute,
      getCoordFromOrderLocationPoint(currentLocation)
    );
  }
  const route = {
    activeRoute,
    subsequentRoute,
    privacyZones: order.privacyZones,
    hashcode: order.directions?.hashcode,
  } as CustomerRoute;
  return route;
};
