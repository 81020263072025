import { connect, ConnectedProps } from "react-redux";
import CarComponent from "./car.component";
import { RootState } from "../../redux/reducers/root.reducer";

const mapState = (state: RootState) => ({
  customerCode: state.customerCodeReducer.customerCode,
  orderDetails: state.orderDetailsReducer.orderDetails,
  orderLocation: state.orderLocationReducer.orderLocation,
  snappedOrderLocation: state.orderLocationReducer.snappedOrderLocation,
  activeRoute: state.routeReducer.route?.activeRoute,
});
const mapDispatch = {};
const connector = connect(mapState, mapDispatch);

export type CarPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CarComponent);
