import { Position } from "@turf/turf";
import { OrderLocation, OrderLocationPoint } from "../../../graphql/types";

export const UPDATE_ORDER_LOCATION = "UPDATE_ORDER_LOCATION";
export const UPDATE_SNAPPED_ORDER_LOCATION = "UPDATE_SNAPPED_ORDER_LOCATION";
export const UPDATE_TRIM_ROUTE_LOCATION = "UPDATE_TRIM_ROUTE_LOCATION";

export interface UpdateOrderLocationAction {
  type: typeof UPDATE_ORDER_LOCATION;
  payload: OrderLocation;
}

export interface UpdateSnappedOrderLocationAction {
  type: typeof UPDATE_SNAPPED_ORDER_LOCATION;
  payload: OrderLocationPoint;
}

export interface UpdateTrimRouteLocationAction {
  type: typeof UPDATE_TRIM_ROUTE_LOCATION;
  payload: Position;
}

export type OrderLocationActionTypes =
  | UpdateOrderLocationAction
  | UpdateTrimRouteLocationAction
  | UpdateSnappedOrderLocationAction;
