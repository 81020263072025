import { ThemeProvider } from "@material-ui/core";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "@reach/router";

import "./App.css";
import { theme } from "./theme";
import store from "./redux/store";
import CustomerCodeComponent from "./components/customer-code/customer-code.container";

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <CustomerCodeComponent path="/*customerCode"></CustomerCodeComponent>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};
