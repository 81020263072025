import { Message } from "../../../models/message.model";
import {
  AddMessageAction,
  ADD_MESSAGE,
  RemoveMessageAction,
  REMOVE_MESSAGE,
} from "./message.action-types";

export const addMessage: (message: Message) => AddMessageAction = (
  message: Message
) => {
  return {
    type: ADD_MESSAGE,
    payload: message,
  } as AddMessageAction;
};

export const removeMessage: (message: Message) => RemoveMessageAction = (
  message: Message
) => {
  return {
    type: REMOVE_MESSAGE,
    payload: message,
  } as RemoveMessageAction;
};
